.btn {
    padding: 10px 20px;
    margin: 0px 10px;
    border-radius: 0px;
    color: #e3bf4b;
    font-family: "Aniron";
    font-size: 12px;
    border-style: solid;
    border-width: 2px;
    border-image-source: linear-gradient(
      to right,
      #b88425,
      #c5973f,
      #d2a957,
      #e0bc6f,
      #edcf88,
      #efd28b,
      #f1d58f,
      #f3d892,
      #eacb80,
      #e1bf6f,
      #d9b25d,
      #d0a64c
    ) !important;
    border-image-slice: 1;
    background-image: linear-gradient(
      to right bottom,
      #000002,
      #101011,
      #1a191a,
      #232323,
      #2c2c2c
    ) !important;
  }
figure {
  margin-bottom: 0;
}
.frame-wrapper {
  /*background-image: url("../img/profileback.png") !important;*/
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: auto;
  padding: 5rem 2rem 5rem;
  margin: 4.5rem 2rem;
  position: relative;
}
.frame-wrapper .border-left img {
  position: absolute;
  left: -22px;
  top: -39px;
}
.frame-wrapper .border-right img {
  position: absolute;
  right: -22px;
  top: -39px;
}
.frame-wrapper .centerLogoDv figure img {
  position: absolute;
  left: 0;
  right: 0;
  top: -70px;
  text-align: center;
  margin: 0px auto;
}
/* Box One Css Start Here */
/* Inventory Css Start Here */
.nav-pills .nav-link.active {
  background: transparent !important;
}
.trade-wrapper h4 {
  color: #ffc300;
  font-size: 30px;
}
.your-offering .inventory-itemBox {
    /* background-image: linear-gradient(to right bottom, #000002, #101011, #1a191a, #232323, #2c2c2c); */
    text-align: center;
    padding: 10px;
    margin: 10px;
    /* border-style: solid;
    border-width: 2px;
    border-image-source: linear-gradient( to right, #b88425, #c5973f, #d2a957, #e0bc6f, #edcf88, #efd28b, #f1d58f, #f3d892, #eacb80, #e1bf6f, #d9b25d, #d0a64c ) !important;
    border-image-slice: 1; */
    height: 73px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.your-offering .inventory-itemBox .count span.large{
    font-size: 12px;
}
.your-offering .inventory-itemBox .count {
    position: absolute;
    bottom: 0;
    right: 0;
    background: linear-gradient( to right, #b88425, #c5973f, #d2a957, #e0bc6f, #edcf88, #efd28b, #f1d58f, #f3d892, #eacb80, #e1bf6f, #d9b25d, #d0a64c ) !important;
    font-family: "Poppins";
    font-weight: 800;
    border-radius: 7px 0 0 0;
    height: 20px;
    line-height: 20px;
    width: 18px;
}

.your-offering {
    padding: 10px;
    background-image: linear-gradient( to right, #181718, #101011, #1a191a, #232323, #171617 );
    border-style: solid;
    border-width: 4px;
    border-image-source: linear-gradient( to right, #b88425, #c5973f, #d2a957, #e0bc6f, #edcf88, #efd28b, #f1d58f, #f3d892, #eacb80, #e1bf6f, #d9b25d, #d0a64c ) !important;
    border-image-slice: 1;
}
.your-offering h5 {
    padding-left: 27px;
    font-size: 14px;
    color: #E3BF4B;
    margin-top: 20px;
    margin-bottom: 30px;
}
.your-offering .form-check-input {
    margin: 0px;
    top: 5px;
}
.your-offering .form-check {
    margin-left: 13px;
}
.your-offering label.form-check-label {
    color: #D8D8D8;
    font-family: 'Poppins';
}
.trade-wrapper {
  padding-top: 100px;
  padding-bottom: 100px;
}
.trade-wrapper .inventroy-Box {
  background-image: linear-gradient(
    to right,
    #181718,
    #101011,
    #1a191a,
    #232323,
    #171617
  );
  padding: 60px 20px 30px;
  margin: -10px 0px -10px;
  border-width: 4px;
  border-image: radial-gradient(#fff3de, #b88425) 1;
  border-style: solid;
  overflow-x: auto;
}
.trade-wrapper .inventroy-Box .inventories {
  padding-top: 88px;
}
.trade-wrapper .inventroy-Box .beforeImg {
  position: absolute;
  right: -10px;
  z-index: 1;
  top: 20px;
}
.trade-wrapper .inventroy-Box .inventories ul.inventory-links {
  padding: 0;
  margin-bottom: 0;
  list-style: none;
}
.trade-wrapper .inventroy-Box .inventories ul.inventory-links li a {
  font-size: 10px;
  color: #e3bf4b;
  font-family: "aniron";
  opacity: 0.6;
}
.trade-wrapper .inventroy-Box .inventories ul.inventory-links li a.active {
  opacity: 1;
}
.trade-wrapper .inventroy-Box .title {
  padding-bottom: 20px;
}
.trade-wrapper .inventroy-Box .search-Box form .form-group input {
  background-image: url(../img/searchicon.png) !important;
  font-size: 9px;
  background-repeat: no-repeat !important;
  background-position: left center !important;
  background-position-x: 5px !important;
  font-family: "Poppins";
  color: #fff;
  border-radius: 0;
  background: transparent;
  border-image-source: linear-gradient(
    to right,
    #b88425,
    #c5973f,
    #d2a957,
    #e0bc6f,
    #edcf88,
    #efd28b,
    #f1d58f,
    #f3d892,
    #eacb80,
    #e1bf6f,
    #d9b25d,
    #d0a64c
  ) !important;
  border-image-slice: 1;
  border-width: 2px;
  padding-left: 25px;
}
.trade-wrapper .inventroy-Box .search-Box form .form-group input::placeholder {
  color: #ffffff7d;
}
.trade-wrapper .inventroy-Box .inventory-itemBox {
  text-align: center;
  border-style: solid;
  border-width: 2px;
  border-image-source: linear-gradient(
    to right,
    #b88425,
    #c5973f,
    #d2a957,
    #e0bc6f,
    #edcf88,
    #efd28b,
    #f1d58f,
    #f3d892,
    #eacb80,
    #e1bf6f,
    #d9b25d,
    #d0a64c
  ) !important;
  height: 70px;
  width: 100%;
  border-image-slice: 1;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}
.trade-wrapper .inventroy-Box .inventory-itemBox .count {
  position: absolute;
  bottom: 0;
  right: 0;
  background: linear-gradient(
    to right,
    #b88425,
    #c5973f,
    #d2a957,
    #e0bc6f,
    #edcf88,
    #efd28b,
    #f1d58f,
    #f3d892,
    #eacb80,
    #e1bf6f,
    #d9b25d,
    #d0a64c
  ) !important;
  font-family: "Poppins";
  font-weight: 800;
  border-radius: 7px 0 0 0;
  height: 20px;
  line-height: 20px;
  width: 18px;
}
.trade-wrapper .inventroy-Box .inventory-itemBox .count span {
  color: #000;
  font-size: 7px;
  font-family: "Poppins";
  font-weight: 800;
}
.trade-wrapper .inventroy-Box .inventory-itemBox .count span.large {
  font-size: 12px;
}
.trade-wrapper .inventroy-Box .nextBtn {
  text-align: right;
}
/* Inventory Css End Here */
.your-offering.up{
    margin-top: 30px !important;
}
.bef {
    height: 102%;
}