/*.frame-wrapper {*/
/*    !*background-image: url("../img/character-bg.png") !important;*!*/
/*    background-size: 100% 100%;*/
/*    background-repeat: no-repeat;*/
/*    padding: 3rem 2rem;*/
/*    margin: 4.5rem 2rem;*/
/*    position: relative;*/
/*  }*/
  .frame-wrapper .centerLogoDv figure img {
    position: absolute;
    left: 0;
    right: 0;
    top: -70px;
    text-align: center;
    margin: 0px auto;
  }
  .frame-wrapper .border-left img {
    position: absolute;
    left: -22px;
    top: -39px;
  }
  .frame-wrapper .border-right img {
    position: absolute;
    right: -22px;
    top: -39px;
  }
  .character-wrapper.active {
    border: 3px solid #cfa550;
  }
  .character-wrapper {
    position: relative;
    border-radius: 9px;
    margin-bottom: 10px;
  }
.profile-creation p {
    color: #FFFAE2;
    font-size: 14px;
}
.profile {
    padding-top: 100px;
    padding-bottom: 100px;
}