@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.cdnfonts.com/css/aniron?styles=10085');
/* / Fonts  / */
@font-face {
    font-family: 'aniron';
    src: url('../fonts/aniron.ttf');
}

/* / Fonts  / */
/* / global css🌍  / */
* {
    font-family: 'Poppins';
}

.overflow-hidden {
    overflow: hidden !important;
}

button {
    outline: 0px !important;
    cursor: pointer !important;
}

::-webkit-scrollbar {
    width: 0;
}

button {
    font-size: 11px;
    font-family: 'aniron';
}

h3 {
    font-size: 16px;
    font-family: 'aniron';
}

h4 {
    font-size: 12px;
    font-family: 'aniron';
}

h5 {
    font-size: 8px;
    font-family: 'aniron';
}

figure {
    margin-bottom: 0;
}

a {
    color: #E3BF4B;
    cursor: pointer !important;
}

a:hover {
    text-decoration: none;
}

.active-wr {
    border: 3px solid #cfa550;
}

a.btn-theme:hover {
    background-position: right;
}


#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow: hidden;
    background: radial-gradient(black, transparent);
}

#preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(42% - 30px);
    border: 6px solid #f2f2f2;
    border-top: 6px solid #FDAB01;
    border-radius: 50%;
    width: 15rem;
    height: 15rem;
}

/* / frame css / */
body {
    background-color: #000 !important;
}


.nav-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 30px;
    padding-top: 20px;
}

.imgBoxStyling {
    margin: 0px auto;
    display: table;
    width: 50px;
    object-fit: cover;
}

/*.customImageBox{*/
/*    background-image: linear-gradient(to right bottom, #000002, #101011, #1a191a, #232323, #2c2c2c);*/
/*    text-align: center;*/
/*    padding: 8px;*/
/*    margin: 8px;*/
/*    border-style: solid;*/
/*    border-width: 2px;*/
/*    border-image-source: linear-gradient( to right, #b88425, #c5973f, #d2a957, #e0bc6f, #edcf88, #efd28b, #f1d58f, #f3d892, #eacb80, #e1bf6f, #d9b25d, #d0a64c ) !important;*/
/*    border-image-slice: 1;*/
/*    height: 73px;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*}*/
.customImageBox {
    background-image: linear-gradient(to right bottom, #000002, #101011, #1a191a, #232323, #2c2c2c);
    text-align: center;
    padding: 8px;
    margin: 8px;
    border-style: solid;
    border-width: 2px;
    border-image-source: linear-gradient(to right, #b88425, #c5973f, #d2a957, #e0bc6f, #edcf88, #efd28b, #f1d58f, #f3d892, #eacb80, #e1bf6f, #d9b25d, #d0a64c) !important;
    border-image-slice: 1;
    height: 73px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85px;
    overflow: hidden;
}

.qualityCounter {
    position: absolute;
    bottom: 0;
    right: 0;
    background: linear-gradient(
            to right,
            #b88425,
            #c5973f,
            #d2a957,
            #e0bc6f,
            #edcf88,
            #efd28b,
            #f1d58f,
            #f3d892,
            #eacb80,
            #e1bf6f,
            #d9b25d,
            #d0a64c
    ) !important;
    font-family: "Poppins";
    font-weight: 800;
    border-radius: 7px 0 0 0;
    height: 20px;
    line-height: 20px;
    width: 18px;
}

.qualityCounter .large {
    color: #000;
    font-size: 7px;
    font-family: "Poppins";
    font-weight: 800;
    font-size: 12px;
}

.returnToBag {
    position: absolute;
    top: 0;
    right: 0;
    background: linear-gradient(
            to right,
            #b82525,
            #c53f3f,
            #d25757,
            #e06f6f,
            #ed8888,
            #ef8b8b,
            #f18f8f,
            #f39292,
            #ea8080,
            #e16f6f,
            #d95d5d,
            #d04c4c
    ) !important;
    font-family: "Poppins";
    font-weight: 800;
    border-radius: 7px 0 0 0;
    height: 20px;
    line-height: 20px;
    width: 35px;
}

.returnToBag .large {
    color: #000;
    font-size: 7px;
    font-family: "Poppins";
    font-weight: 800;
    font-size: 15px;
}

.characterProfilePic {
    width: 100px;
    height: 140px;
    object-fit: fill;
    padding: 7px 5px;
    /* border-style: solid; */
    /* border-width: 7px; */
    /* border-image: radial-gradient(#fff3de, #B88425) 1; */
    background-color: #2f3130;
}

.ProfileContent-Dv .GuildsDv .discoo .imgBox figure {
    margin: 0;
}

.inventory-itemBox.customImageBox img {
    height: 73px;
    width: 100%;
    object-fit: cover;
    padding: 0px;
}

.inventory-itemBox.customImageBox figure {
    padding: 0px !important;
    margin: 0px;
}

.inventory-itemBox.customImageBox {
    padding: 0px;
}

.inventory-itemBox .count span.large {
    font-size: 12px;
}

.inventory-itemBox .count {
    position: absolute;
    bottom: 0;
    right: 0;
    background: linear-gradient(to right, #b88425, #c5973f, #d2a957, #e0bc6f, #edcf88, #efd28b, #f1d58f, #f3d892, #eacb80, #e1bf6f, #d9b25d, #d0a64c) !important;
    font-family: "Poppins";
    font-weight: 800;
    border-radius: 7px 0 0 0;
    height: 20px;
    line-height: 20px;
    width: 18px;
}

/*.frame-wrapper {*/
/*    background-image: url(../img/frame2.png);*/
/*    background-size: 100% 100%;*/
/*    background-repeat: no-repeat;*/
/*    !* height: 100vh; *!*/
/*    padding: 4rem 1.7rem 3.4rem;*/
/*}*/
/* / frame css  / */
/* / global css  / */

/* / Logo Css Start Here  / */
.TopCenter .logoDv {
    max-width: 25%;
    position: absolute;
    right: 0;
    left: 0;
    margin: 0px auto;
    top: -1.5rem;
    z-index: 9;
}

a.btn-theme {
    background-size: 134% !important;
    background-position: left !important;
    transition: all 0.3s ease-in;
}

.Menus .dropdown-menu {
    background-image: radial-gradient(circle, #2c2c2c, #262626, #212121, #1b1b1b, #161616);
    border-radius: 0px !important;
}

.Menus a.dropdown-item {
    color: #e3bf4b;
    font-family: 'aniron';
    font-size: 11px;
    padding: 10px;
    text-align: center;
}

.Menus a.dropdown-item:hover {
    background: #e3bf4b;
    color: #161616;
}

a.btn-theme:hover {
    background-position: right !important;
}

.TopCenter .logoDv figure img {
    width: 100%;
}

/* / Logo Css Start Here / */
.character-wrapper img.char-img {
    filter: brightness(0.5);
    transition: all 0.3s ease-in;
}

.character-wrapper img.char-img:hover {
    filter: brightness(0.9);
}

/* / Menu Css Start Here  / */

.nav-menu ul.menus {
    padding: 0;
    margin: 0;
    padding-right: 60px;
}

.nav-menu ul.menus li.menu-items {
    display: inline-block;
}

.nav-menu ul.menus li.menu-items a#nav-dropdown-dark-example,
.nav-menu ul.menus li.menu-items a.menu-links {
    color: #E3BF4B;
    font-size: 12px;
    font-family: 'aniron';
    text-decoration: none;
    transition: all 0.6s linear;
    border: 1px solid transparent;
    padding: 6px 18px;
}

.nav-menu ul.menus li.menu-items a#nav-dropdown-dark-example:hover,
.nav-menu ul.menus li.menu-items a.menu-links:hover {
    color: #f1c536;
    padding: 6px 9px;
    border: 1px solid #f1c536;
    background-image: radial-gradient(circle, #2c2c2c, #262626, #212121, #1b1b1b, #161616);
}

.fram-sec .golden-color {
    color: #FFE698;
    font-family: 'Poppins';
    font-weight: 300;
}

/* / Menu Css End Here / */

.character-list {
    margin-bottom: 30px;
}

/* navbar hover effect starts here */

/*Custom*/
.customInput {
    background-image: linear-gradient(to right bottom, #000002, #101011, #1a191a, #232323, #2c2c2c) !important;
    border-radius: 0px !important;
    color: #E3BF4B !important;
    font-family: 'aniron';
    font-size: 10px;
    padding: 10px !important;
    border: 0px solid !important;
    border-style: solid !important;
    border-width: 2px !important;
    border-image-source: linear-gradient(to right, #b88425, #c5973f, #d2a957, #e0bc6f, #edcf88, #efd28b, #f1d58f, #f3d892, #eacb80, #e1bf6f, #d9b25d, #d0a64c) !important;
    border-image-slice: 1 !important;
}

.customButton {
    background-image: linear-gradient(to right bottom, #000002, #101011, #1a191a, #232323, #2c2c2c);
    border-radius: 0px;
    color: #E3BF4B !important;
    font-family: 'aniron';
    font-size: 12px;
    padding: 11px 20px;
    border: 0px solid;
    border-style: solid;
    border-width: 2px;
    border-image-source: linear-gradient(to right, #b88425, #c5973f, #d2a957, #e0bc6f, #edcf88, #efd28b, #f1d58f, #f3d892, #eacb80, #e1bf6f, #d9b25d, #d0a64c) !important;
    border-image-slice: 1;
}

.pdlCustomInput {
    background-image: url(../img/searchicon.png) !important;
    font-size: 9px !important;;
    background-repeat: no-repeat !important;
    background-position: left center !important;
    background-position-x: 5px !important;
    font-family: "Poppins" !important;;
    color: #fff !important;
    border-radius: 0 !important;;
    background: transparent !important;;
    border-image-source: linear-gradient(
            to right,
            #b88425,
            #c5973f,
            #d2a957,
            #e0bc6f,
            #edcf88,
            #efd28b,
            #f1d58f,
            #f3d892,
            #eacb80,
            #e1bf6f,
            #d9b25d,
            #d0a64c
    ) !important;
    border-image-slice: 1 !important;;
    border-width: 2px !important;;
    padding-left: 25px !important;;
}

.pdlCustomInput::placeholder {
    color: #fff !important;;
}

/*Custom*/


/* navbar hover effect ends here */
/* / footer css start here  / */
footer {
    padding: 20px;
}

.footer-bg {
    background: url(../img/footer.png);
    padding: 82px 0px;
    background-size: 100%;
    background-repeat: no-repeat;
}

footer ul#footer-menu1 {
    list-style-type: none;
    display: flex;
    justify-content: center;
    padding: 0px;
}

footer ul#footer-menu1 li {
    margin: 0px 25px;
}

footer ul#footer-menu1 li a {
    color: #FFE698;
    font-family: 'montserrat';
    font-size: 16px;
    font-weight: 500;
    padding: 10px;
}

footer .footer-des {
    color: #C9C9C9;
    font-family: 'montserrat';
    font-size: 16px;
    font-weight: 400;
}

@media (max-width: 1280px) {
    .footer-bg {
        padding: 70px 0px;
        background-size: 100% 100%;
    }
}

@media (max-width: 990px) {
    footer ul#footer-menu1 li {
        margin: 0px 9px;
    }
}

@media (max-width: 770px) {
    .footer-bg {
        padding: 43px 0px;
        background-size: 100% 100%;
    }

    footer .footer-des {
        font-size: 13px;
    }

    footer ul#footer-menu1 li a {
        font-size: 14px;
    }
}

@media (max-width: 580px) {
    .footer-bg {
        padding: 30px 0px 25px 0px;
        background-size: 100% 100%;
    }
}

@media (max-width: 500px) {
    footer ul#footer-menu1 li {
        margin: 0px;
    }

    footer ul#footer-menu1 li a {
        font-size: 13px;
    }
}

@media (max-width: 479px) {
    .footer-bg {
        background-image: url('../img/footer-color.png');
        background-size: cover;
        background-position: center center;
        border: 10px solid #C08F34;
        border-radius: 10px;
    }

    footer .footer-des {
        text-align: left;
    }

    footer ul#footer-menu1 {
        flex-direction: column;
        align-items: flex-start;
    }

    footer ul#footer-menu1 li {
        margin: 0px;
    }

}


.castle-menu-links {
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    max-width: 67%;
    margin: 0px auto;
}

.castle-menu-links li {
    display: inline-block;
    margin-bottom: 10px;
    margin: 3px;
}

.castle-menu-links li a {
    background-image: linear-gradient(to right,
    #000002,
    #101011,
    #1a191a,
    #232323,
    #2c2c2c);
    background-repeat: no-repeat;
    background-position: left;
    color: #ffe698 !important;
    font-family: "aniron";
    font-size: 10px;
    border: 3px solid;
    border-image-source: linear-gradient(to right,
    #b88425,
    #c5973f,
    #d2a957,
    #e0bc6f,
    #edcf88,
    #efd28b,
    #f1d58f,
    #f3d892,
    #eacb80,
    #e1bf6f,
    #d9b25d,
    #d0a64c) !important;
    border-image-slice: 1;
    border-width: 3px;
    transition: all 0.3s ease-in-out;
    padding: 9px 20px;
    display: block;
}

/* / footer css start here  / */


/*PDL Custom Styling*/
.pdlFont {
    font-family: 'aniron' !important;
}

.pdlStyleHeading {
    color: #E3BF4B !important;
    font-size: 18px !important;
    font-family: 'aniron' !important;
}

.pdlStyleParagraph {
    color: #FFE698 !important;
    font-family: 'Poppins' !important;
    font-weight: 300 !important;
}

.corner-characters {
    position: fixed;
    right: 60px;
    bottom: 60px;
}
.corner-characters .current-state-character figure img {
    border-radius: 50%;
    height: 60px;
    width: 60px;
    border: 3px solid #cda55c;
    box-shadow: #cda55c 0px 4px 12px 1px;

}
/*.corner-characters .current-state-character figure img {*/
/*    border-radius: 50%;*/
/*    height: 60px;*/
/*    width: 60px;*/
/*}*/

.corner-characters ul.my-characters-list {
    position: absolute;
    bottom: 0px;
    right: 0px;
    list-style: none;
    padding-left: 0px;
}

.corner-characters ul.my-characters-list img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    /*border: 3px solid #cda55c;*/
    box-shadow: #cda55c 0px 4px 12px 1px;
    object-fit: cover;
}

.corner-characters ul.my-characters-list .close-ico {
    text-align: center;
    border: 0px;
    width: 60px;
    background: #e3bf4b;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: #cda55c 0px 4px 12px 1px;
}

.corner-characters ul.my-characters-list .close-ico figure {
    margin: 0px;
}

.corner-characters ul.my-characters-list .close-ico figure i {
    color: #fff;
    font-size: 24px;
}

.corner-characters ul.my-characters-list figure {
    position: relative;
}


.pdlSelect {
    background-image: linear-gradient(to right bottom, #000002, #101011, #1a191a, #232323, #2c2c2c)!important;
    border-radius: 0px;
    color: #E3BF4B !important;
    font-family: 'aniron';
    font-size: 10px;
    padding: 10px;
    border: 0px solid;
    border-style: solid;
    border-width: 2px;
    border-image-source: linear-gradient(to right, #b88425, #c5973f, #d2a957, #e0bc6f, #edcf88, #efd28b, #f1d58f, #f3d892, #eacb80, #e1bf6f, #d9b25d, #d0a64c) !important;
    border-image-slice: 1;
}

/*PDL Custom Styling*/

/* Begin of Other Css */
a{
    text-decoration: none;
    color: black;
  }
  
  a:visited{
    color: black;
  }
  
  .all-notification-stuff .box::-webkit-scrollbar-track
  {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: transparent;
    border-radius: 5px
  }
  
  .all-notification-stuff .box::-webkit-scrollbar
  {
      width: 10px;
      background-color: transparent;
    border-radius: 5px
  }
  
  .all-notification-stuff .box::-webkit-scrollbar-thumb
  {
      background-color: black;
      border: 2px solid black;
    border-radius: 5px
  }
  
  .all-notification-stuff header{
    -moz-box-shadow: 10px 10px 23px 0px rgba(0,0,0,0.1);
    box-shadow: 10px 10px 23px 0px rgba(0,0,0,0.1);
    height: 110px;
    vertical-align: middle;
  }
  
  
  
  
  .all-notification-stuff .icons{
    display: inline;
    float: right
  }
  
  .all-notification-stuff .notification {
    padding-top: 30px;
    position: relative;
    display: inline-block;
    top: 2px;
}
.all-notification-stuff .notification a {
    color: #e3bf4b !important;
}
  
.all-notification-stuff .number{
    height: 22px;
    width:  22px;
    background-color: #d63031;
    border-radius: 20px;
    color: white;
    text-align: center;
    position: absolute;
    top: 23px;
    left: 60px;
    padding: 3px;
    border-style: solid;
    border-width: 2px;
  }
  
  .all-notification-stuff .number:empty {
     display: none;
  }
  
 .all-notification-stuff .notBtn{
    transition: 0.5s;
    cursor: pointer
  }
  
  .all-notification-stuff .fas{
    font-size: 25pt;
    padding-bottom: 10px;
    color: black;
    margin-right: 40px;
    margin-left: 40px;
  }
  
  .all-notification-stuff .box{
    width: 400px;
    height: 0px;
    border-radius: 10px;
    transition: 0.5s;
    position: absolute;
    overflow-y: scroll;
    padding: 0px;
    left: -300px;
    margin-top: 5px;
    /* background-color: #F4F4F4; */
    background-image: url(../img/charastats.png);
    -webkit-box-shadow: 10px 10px 23px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 10px 10px 23px 0px rgba(0,0,0,0.1);
    box-shadow: 10px 10px 23px 0px rgba(0,0,0,0.1);
    cursor: context-menu;
  }
  
  .all-notification-stuff .fas:hover {
    color: #d63031;
  }
  
  .all-notification-stuff .notBtn:hover > .box {
    height: 583px;
}
  
.all-notification-stuff .content{
    padding: 20px;
    color: black;
    vertical-align: middle;
    text-align: left;
  }
  
  .all-notification-stuff .gry{
    background-color: #F4F4F4;
  }
  
  .all-notification-stuff .top{
    color: black;
    padding: 10px
  }
  
  .all-notification-stuff .display{
    position: relative;
  }
  
  .all-notification-stuff .cont{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #F4F4F4;
  }
  
  .all-notification-stuff .cont:empty{
    display: none;
  }
  
  .all-notification-stuff .stick{
    text-align: center;  
    display: block;
    font-size:10px ;
    padding-top: 70px;
    padding-left: 80px
  }
  
  .all-notification-stuff .stick:hover{
    color: black;
  }
  
  .all-notification-stuff .cent{
    text-align: center;
    display: block;
  }
  
  .all-notification-stuff .sec{
    padding: 25px 10px;
    background-color: #F4F4F4;
    transition: 0.5s;
  }
  
  .all-notification-stuff .profCont{
    padding-left: 15px;
  }
  
  .all-notification-stuff .profile{
    -webkit-clip-path: circle(50% at 50% 50%);
    clip-path: circle(50% at 50% 50%);
    width: 75px;
    float: left;
  }
  
  .all-notification-stuff .txt{
    vertical-align: top;
    font-size: 1.25rem;
    padding: 5px 10px 0px 115px;
  }
  
  .all-notification-stuff .sub{
    font-size: 1rem;
    color: grey;
  }
  
  .all-notification-stuff .new{
    border-style: none none solid none;
    border-color: red;
  }
  
  .all-notification-stuff .sec:hover{
    background-color: #BFBFBF;
  }

    li.menu-items.dropdown.notificationDropdown .dropdown-toggle::after {
        display: none!important;
    }
/* =====================   */




