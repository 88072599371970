.wall-of-fame {
    padding: 100px 0px;
}

.wall-of-fame h3 {
    color: #E3BF4B;
    font-size: 22px;
}

.wall-of-fame .card-wrapper {
    border: 3px solid;
    border-image-source: linear-gradient(to right,
    #b88425,
    #c5973f,
    #d2a957,
    #e0bc6f,
    #edcf88,
    #efd28b,
    #f1d58f,
    #f3d892,
    #eacb80,
    #e1bf6f,
    #d9b25d,
    #d0a64c) !important;
    border-image-slice: 1;
    border-width: 3px;
    padding: 4px;
    height: 298px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.wall-of-fame .card-wrapper .img-col-wrapper {
    display: flex;
    justify-content: center;
    border: 0px solid;
    border-image-source: linear-gradient(to right,
    #b88425,
    #c5973f,
    #d2a957,
    #e0bc6f,
    #edcf88,
    #efd28b,
    #f1d58f,
    #f3d892,
    #eacb80,
    #e1bf6f,
    #d9b25d,
    #d0a64c) !important;
    border-image-slice: 1;
    border-bottom-width: 1px;
}

.wall-of-fame .card-wrapper .img-col-wrapper img {
    margin: 11px 4px;
    height: 56px;
    width: 39px;
    object-fit: cover;
}

.wall-of-fame .card-wrapper .img-col-wrapper:last-child {
    border-bottom: 0px;
}

.wall-of-fame .card-name-wrapper {
    padding: 10px 0px;
    text-align: center;
}

.wall-of-fame .card-name-wrapper h4 {
    font-size: 15px;
    font-family: 'Aniron';
    color: #E3BF4B;
}
.wall-of-fame .img-card-wrapper {
    margin: 10px 0px;
    /*height: 343px !important;*/
    overflow: hidden;
}