.char-details .Player-statusDv {
    padding: 19px;
}
.char-details figure {
    margin: 0px;
}
.char-details .head {
    margin-top: 10px;
}
.char-details .head h3.title {
    font-size: 15px;
    text-align: center;
    color: #E3BF4B;
}