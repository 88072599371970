/*.frame-wrapper {*/
/*	background-image: url("../img/inventorybg.png");*/
/*	background-size: 100% 100%;*/
/*	background-repeat: no-repeat;*/
/*	padding: 3rem 2rem;*/
/*	margin: 4.5rem 2rem;*/
/*	position: relative;*/
/*}*/
.frame-wrapper .centerLogoDv figure img {
	position: absolute;
	left: 0;
	right: 0;
	top: -70px;
	text-align: center;
	margin: 0px auto;
}
.frame-wrapper .border-left img {
	position: absolute;
	left: -22px;
	top: -39px;
}
.frame-wrapper .border-right img {
	position: absolute;
	right: -22px;
	top: -39px;
}
.character-wrapper.active {
	border: 3px solid #cfa550;
}
.character-wrapper {
	position: relative;
	border-radius: 9px;
	margin-bottom: 10px;
}
.profile-creation p {
	color: #fffae2;
	font-size: 14px;
}
.profile {
	padding-top: 100px;
	padding-bottom: 100px;
}
.mycharacter-sec {
	padding-top: 70px;
	padding-bottom: 70px;
}
.mycharacter-sec .bounty-img-wrapper {
    /* background-image: url("../img/bounty-bg.png"); */
    background-size: 100% 100%;
    padding: 30px 10px 20px;
    height: 440px;
}
.mycharacter-sec .bounty-hunter-wrapper .hunter-listing {
    height: 275px;
    overflow-y: scroll;
    padding-right: 10px;
}
.mycharacter-sec .bounty-hunter-wrapper .hunter-listing::-webkit-scrollbar {
	width: .2em;
}
   
.mycharacter-sec .bounty-hunter-wrapper .hunter-listing::-webkit-scrollbar-thumb {
	background-color: #e3bf4b;
}
.mycharacter-sec .bount-heading-wrapper h5 {
    text-align: center;
    color: #e3bf4b;
    font-size: 12px;
    text-transform: uppercase;
    margin: 0px;
    font-family: 'aniron';
}

.mycharacter-sec .bount-heading-wrapper {
    padding: 5px 0px;
}
.mycharacter-sec .bounty-img-wrapper .bount-img img {
    height: 370px;
    width: 100%;
    object-fit: contain;
    object-position: top;
}
.mycharacter-sec .bounty-hunter-wrapper {
	/*background-image: url("../img/bounty-bg.png");*/
	background-size: 100% 100%;
	padding: 46px 20px;
}
.bounty-hunter-wrapper .bounty-heading-wrapper h5 {
	text-align: center;
	color: #e3bf4b;
	font-size: 14px;
	text-transform: uppercase;
	margin: 0px;
}
.bounty-hunter-wrapper .hunter-stats-wrapper h5 {
	text-align: center;
	color: #e3bf4b;
	font-size: 11px;
	text-transform: uppercase;
	margin: 0px;
}
.bounty-hunter-wrapper .hunter-list-wrappper {
	padding: 13px 0px;
}
.bounty-hunter-wrapper .hunter-list-wrappper ul {
	list-style: none;
	padding: 0px;
	margin: 0px;
}
.bounty-hunter-wrapper .hunter-list-wrappper ul li {
	display: flex;
	justify-content: space-between;
	padding: 7px 0px;
	border-bottom: 1px solid #313131;
	align-items: center;
}
.bounty-hunter-wrapper .hunter-list-wrappper ul li span.property {
	color: #e3bf4b;
	font-family: "Aniron";
	font-size: 8px;
}
.bounty-hunter-wrapper .hunter-list-wrappper ul li span.value {
	color: #bfbfbf;
	font-family: "Aniron";
	font-weight: 700;
	font-size: 13px;
}
.mycharacter-sec .weapons-wrapper .single-weapon-wrapper .weapon-img-wrapper img {
	border-image-source: linear-gradient( to right, #b88425, #c5973f, #d2a957, #e0bc6f, #edcf88, #efd28b, #f1d58f, #f3d892, #eacb80, #e1bf6f, #d9b25d, #d0a64c ) !important;
	border-image-slice: 1 !important;
	border-width: 2px;
	border: 2px solid;
}
.mycharacter-sec .bounty-hunter-wrapper .hunter-btn-wrapper .btn {
	background-image: linear-gradient(
		to right,
		#000002,
		#101011,
		#1a191a,
		#232323,
		#2c2c2c
	);
	background-repeat: no-repeat;
	background-size: 130%;
	background-position: left;
	padding: 9px 27px;
	color: #ffe698 !important;
	font-family: "aniron";
	font-size: 7px;
	border: 1px solid;
	border-image-source: linear-gradient(
		to right,
		#b88425,
		#c5973f,
		#d2a957,
		#e0bc6f,
		#edcf88,
		#efd28b,
		#f1d58f,
		#f3d892,
		#eacb80,
		#e1bf6f,
		#d9b25d,
		#d0a64c
	) !important;
	border-image-slice: 1;
	border-width: 1px;
	transition: all 0.3s ease-in-out;
}
.mycharacter-sec .bounty-hunter-wrapper .hunter-btn-wrapper {
	padding: 11px 0px;
}
.mycharacter-sec .weapons-wrapper {
	/*background-image: url("../img/bounty-bg.png");*/
	background-size: 100% 100%;
	padding: 46px 20px;
}
.mycharacter-sec .weapons-wrapper ul {
	padding: 0px;
	list-style: none;
	margin: 0;
	display: flex;
	flex-wrap: wrap;
}
.mycharacter-sec .single-weapon-wrapper .weapon-heading-wrapper h6 {
	font-size: 12px;
	font-weight: 700;
	font-family: "Aniron";
	color: #e3bf4b;
}
.mycharacter-sec .single-weapon-wrapper .weapon-heading-wrapper {
	padding: 16px 0px;
}
.mycharacter-sec .weapons-wrapper ul li {
	display: inline-block;
	width: 32%;
	text-align: center;
}
.mycharacter-sec .weapons-wrapper .hunter-btn-wrapper .btn {
	background-image: linear-gradient(
		to right,
		#000002,
		#101011,
		#1a191a,
		#232323,
		#2c2c2c
	);
	background-repeat: no-repeat;
	background-size: 130%;
	background-position: left;
	padding: 9px 27px;
	color: #ffe698 !important;
	font-family: "aniron";
	font-size: 7px;
	border: 1px solid;
	border-image-source: linear-gradient(
		to right,
		#b88425,
		#c5973f,
		#d2a957,
		#e0bc6f,
		#edcf88,
		#efd28b,
		#f1d58f,
		#f3d892,
		#eacb80,
		#e1bf6f,
		#d9b25d,
		#d0a64c
	) !important;
	border-image-slice: 1;
	border-width: 1px;
	transition: all 0.3s ease-in-out;
}
.weapons-wrapper .hunter-btn-wrapper {
    padding: 10px 0px 5px 0px;
}
.mycharacter-sec .weapons-wrapper .single-weapon-wrapper {
	padding: 10px 0px;
}
div#BountyModal .modal-content {
	/*background-image: url("../img/modal-bg.png");*/
	background-repeat: no-repeat;
	background-size: cover;
	background-color: transparent;
}
div#BountyModal .modal-dialog {
	max-width: 600px;
	margin: 30px auto;
}
#BountyModal .modal-body {
	padding: 76px;
	text-align: center;
}

#BountyModal .modal-body h4 {
	font-family: "Aniron";
	font-size: 18px;
	color: #e3bf4b;
}

#BountyModal .modal-body p {
	color: #fff1d2;
	font-family: "Montserrat";
}
div#BountyModal .btn {
	background-image: linear-gradient(
		to right,
		#000002,
		#101011,
		#1a191a,
		#232323,
		#2c2c2c
	);
	background-repeat: no-repeat;
	background-size: 130%;
	background-position: left;
	padding: 9px 32px;
	color: #ffe698 !important;
	font-family: "aniron";
	font-size: 12px;
	border: 1px solid;
	border-image-source: linear-gradient(
		to right,
		#b88425,
		#c5973f,
		#d2a957,
		#e0bc6f,
		#edcf88,
		#efd28b,
		#f1d58f,
		#f3d892,
		#eacb80,
		#e1bf6f,
		#d9b25d,
		#d0a64c
	) !important;
	border-image-slice: 1;
	border-width: 1px;
	transition: all 0.3s ease-in-out;
}
div#BountyModal .modal-content {
	/*background-image: url("../img/modal-bg.png");*/
	background-repeat: no-repeat;
	background-size: 100%;
	background-color: transparent;
}
