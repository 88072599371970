/*.frame-wrapper {*/
/*  !*background-image: url("../img/warriorbg.png") !important;*!*/
/*  background-size: 100% 100%;*/
/*  background-repeat: no-repeat;*/
/*  padding: 3rem 2rem;*/
/*  margin: 4.5rem 2rem;*/
/*  position: relative;*/
/*}*/
.frame-wrapper .centerLogoDv figure img {
  position: absolute;
  left: 0;
  right: 0;
  top: -70px;
  text-align: center;
  margin: 0px auto;
}
.frame-wrapper .border-left img {
  position: absolute;
  left: -22px;
  top: -39px;
}
.frame-wrapper .border-right img {
  position: absolute;
  right: -22px;
  top: -39px;
}
.active-wr {
  border: 3px solid #cfa550;
}
.character-list {
  margin-bottom: 42px;
}
.character-wrapper {
  position: relative;
  border-radius: 9px;
  margin-bottom: 10px;
}
.character-wrapper figure {
  margin: 0px;
}
.character-wrapper .char-name {
  position: absolute;
  left: 9px;
  top: 33%;
}
.character-wrapper img.char-img {
  filter: brightness(0.5);
  width: 100%;
}
.progress {
  background-color: #2e2e2e;
  height: 0.5rem !important;
  border-radius: 0px !important;
}
.golden-bar {
  background-image: linear-gradient(
    to right,
    #b88425,
    #c5973f,
    #d2a957,
    #e0bc6f,
    #edcf88,
    #efd28b,
    #f1d58f,
    #f3d892,
    #eacb80,
    #e1bf6f,
    #d9b25d,
    #d0a64c
  ) !important;
}
.character-specification .spec-name-and-percent {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
}
.spec-name-and-percent h6 {
  color: #ffe799;
  font-family: "aniron";
  font-weight: bold;
  font-size: 10px;
  margin-bottom: 0px;
}
.spec-name-and-percent h5 {
  color: #ffe799;
  font-family: "aniron";
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 0px;
}
.character-progress {
  margin: 20px 0px;
}
.character-specification {
  margin-top: 45px;
}
a.btn-theme {
  background-image: linear-gradient(
    to right,
    #000002,
    #101011,
    #1a191a,
    #232323,
    #2c2c2c
  );
  background-repeat: no-repeat;
  padding: 15px 70px;
  color: #ffe698 !important;
  font-family: "aniron";
  font-size: 15px;
  border: 3px solid;
  border-image-source: linear-gradient(
    to right,
    #b88425,
    #c5973f,
    #d2a957,
    #e0bc6f,
    #edcf88,
    #efd28b,
    #f1d58f,
    #f3d892,
    #eacb80,
    #e1bf6f,
    #d9b25d,
    #d0a64c
  ) !important;
  border-image-slice: 1;
  border-width: 3px;
  display: inline-block !important;
}
a.btn-theme2 {
  background-image: linear-gradient(
    to right,
    #ff0000,
    #e40a10,
    #c81318,
    #ac191c,
    #911c1e,
    #821b29,
    #711e30,
    #5f2133,
    #50263b,
    #402b3c,
    #332c37,
    #2c2c2c
  );
  background-repeat: no-repeat;
  padding: 11px 45px;
  color: #ffe698 !important;
  font-family: "aniron";
  font-size: 11px;
  border: 3px solid;
  border-image-source: linear-gradient(
    to right,
    #b88425,
    #c5973f,
    #d2a957,
    #e0bc6f,
    #edcf88,
    #efd28b,
    #f1d58f,
    #f3d892,
    #eacb80,
    #e1bf6f,
    #d9b25d,
    #d0a64c
  ) !important;
  border-image-slice: 1;
  border-width: 3px;
  display: inline-block !important;
}
/* character-sec css start here */
.character {
  padding-top: 60px;
  padding-bottom: 60px;
}
.character p.char-des {
  color: #fff;
  font-weight: 300;
}
.character a.cta {
  text-decoration: underline;
  font-family: "aniron";
  font-size: 11px;
}
.character .character-wrapper {
  margin: 30px 0px;
}
.btn-wrapper{
    padding-top: 30px;
}
.character .btn-wrapper a.btn-theme {
    font-size: 11px;
    padding: 14px 7px;
}
/* character-sec css ends here */
/* para sec css starts here */
.para-sec p {
  font-size: 18px;
  font-family: "aniron";
  color: #fff;
  text-align: center;
}
/* para sec css ends here */
/* ruby sec starts here */
.rudy-sec {
    padding-top: 100px;
    padding-bottom: 100px;
}
.ruby-wrapper {
  background-image: linear-gradient(
    to left,
    #000002,
    #101011,
    #1a191a,
    #232323,
    #2c2c2c
  );
  padding: 20px;
  border: 3px solid;
  border-radius: 0rem !important;
  border-image-source: linear-gradient(
    to right,
    #b88425,
    #c5973f,
    #d2a957,
    #e0bc6f,
    #edcf88,
    #efd28b,
    #f1d58f,
    #f3d892,
    #eacb80,
    #e1bf6f,
    #d9b25d,
    #d0a64c
  ) !important;
  border-image-slice: 1;
  border-width: 3px;
  display: flex;
  align-items: center;
  height: 212px;
}
.ruby-content-wrapper h4 {
  color: #e3bf4b;
  font-family: "aniron";
  font-size: 18px;
}
.ruby-img-wrapper img {
    transform: scale(1.5);
}
/* ruby sec ends here */
/* happens sections starts here */
.happens-bg {
    background: url('../img/happensbg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0px 2rem;
    padding: 5rem 0rem;
}
.what-happens h4 {
    color: #E3BF4B;
    font-size: 30px;
    font-family: 'aniron';
}
.what-happens p {
    font-size: 18px;
    font-family: 'Montserrat';
    color: #fff;
}
/* happens sections ends here */
/* intractive sec css starts here */
.intractive-sec{
    padding-top: 100px;
    padding-bottom: 100px;
}
.intractive-sec-img img {
    border-radius: 10px;
}
.intractive-sec .intractive-content h4 {
    color: #fff;
    font-family: 'aniron';
    font-size: 30px;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 10px;
}

/* intractive sec css ends here */