.frame-wrapper {
  /*background-image: url("../img/character-bg.png") !important;*/
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 3rem 2rem;
  margin: 4.5rem 2rem;
  position: relative;
}
.frame-wrapper .centerLogoDv figure img {
  position: absolute;
  left: 0;
  right: 0;
  top: -70px;
  text-align: center;
  margin: 0px auto;
}
.frame-wrapper .border-left img {
  position: absolute;
  left: -22px;
  top: -39px;
}
.frame-wrapper .border-right img {
  position: absolute;
  right: -22px;
  top: -39px;
}
.character-wrapper.active {
  border: 3px solid #cfa550;
}
.character-wrapper {
  position: relative;
  border-radius: 9px;
  margin-bottom: 10px;
}
.guild {
  padding-top: 100px;
  padding-bottom: 100px;
}
.guild .nav-tabs {
  border-bottom: 0px;
}
.btn {
  padding: 10px 20px;
  margin: 0px 10px;
  border-radius: 0px;
  color: #e3bf4b;
  font-family: "Aniron";
  font-size: 12px;
  border-style: solid;
  border-width: 2px;
  border-image-source: linear-gradient(
    to right,
    #b88425,
    #c5973f,
    #d2a957,
    #e0bc6f,
    #edcf88,
    #efd28b,
    #f1d58f,
    #f3d892,
    #eacb80,
    #e1bf6f,
    #d9b25d,
    #d0a64c
  ) !important;
  border-image-slice: 1;
  background-image: linear-gradient(
    to right bottom,
    #000002,
    #101011,
    #1a191a,
    #232323,
    #2c2c2c
  ) !important;
}
.guild .nav-tabs .nav-link.active {
  padding: 10px 20px;
  margin: 0px 10px;
  border-radius: 0px;
  color: #e3bf4b;
  font-family: "Aniron";
  font-size: 12px;
  border-style: solid;
  border-width: 2px;
  border-image-source: linear-gradient(
    to right,
    #b88425,
    #c5973f,
    #d2a957,
    #e0bc6f,
    #edcf88,
    #efd28b,
    #f1d58f,
    #f3d892,
    #eacb80,
    #e1bf6f,
    #d9b25d,
    #d0a64c
  ) !important;
  border-image-slice: 1;
  background-image: linear-gradient(
    to right bottom,
    #000002,
    #101011,
    #1a191a,
    #232323,
    #2c2c2c
  );
}
.guild .nav-link {
  padding: 10px 20px;
  margin: 0px 10px;
  border-radius: 0px;
  color: #e3bf4b;
  font-family: "Aniron";
  font-size: 12px;
  border-style: solid;
  border-width: 2px;
  border-image-source: linear-gradient(
    to right,
    #b88425,
    #c5973f,
    #d2a957,
    #e0bc6f,
    #edcf88,
    #efd28b,
    #f1d58f,
    #f3d892,
    #eacb80,
    #e1bf6f,
    #d9b25d,
    #d0a64c
  ) !important;
  border-image-slice: 1;
  background-image: linear-gradient(
    to right bottom,
    #000002,
    #101011,
    #1a191a,
    #232323,
    #2c2c2c
  );
}
.search-wrapper img.guld-sear {
  position: absolute;
  top: 34%;
  left: 1%;
}
.search-wrapper {
  position: relative;
}
.guild .search-wrapper input {
  background: transparent;
  padding: 10px 22px;
  border-bottom: 2px solid #ffe597;
  border-radius: 0px;
  font-size: 12px;
  font-family: "Poppins";
}
.guild .search-wrapper input::placeholder {
  color: #707070;
}
.guild-wrapper {
  height: 500px;
  overflow: scroll;
}
.guild-card-wrapper .guild-img-wrapper img {
  width: 120px;
  height: 170px;
}
.guild-card-wrapper {
    display: flex;
    margin-top: 62px;
}
.guild-name-and-join {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.guild-card-wrapper h5.guild-title {
  color: #e3bf4b;
  font-size: 16px!important;
  margin-bottom: 0px;
}
.guild-description p {
  color: #fff;
  font-size: 11px;
}
.marcus-and-member span.total-member {
    color: #fff;
}
.guild-main-wrapper, .guild-img-wrapper img.guild-img {
  border-style: solid;
  border-width: 3px;
  border-image-source: linear-gradient(
to right,
#b88425,
#c5973f,
#d2a957,
#e0bc6f,
#edcf88,
#efd28b,
#f1d58f,
#f3d892,
#eacb80,
#e1bf6f,
#d9b25d,
#d0a64c
) !important;
  border-image-slice: 1;
  height: auto;
  width: 100%;
}
.marcus-and-member h5 {
  margin: 0px 18px 0px 0px;
}
.marcus-and-member {
  display: flex;
}
.marcus-and-member h5 {
  margin: 0px 18px 0px 0px;
  font-family: "montserrat";
  font-size: 13px;
  color: #e3bf4b;
}
.marcus-and-member h5 img {
  transform: scale(1.5);
}
