/* Auction Css Start Here */
/*.welcome_video video {*/
/*	position: absolute;*/
/*	top: 0;*/
/*	z-index: 99999;*/
/*}*/
.welcome_video video {
	position: absolute;
	top: 0;
	z-index: 99999;
	height: 100vh;
	width: 100%;
	left: 0;
	object-fit: cover;
}
.auction-frame-wrapper .slick-next:before {
	content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAABHNCSVQICAgIfAhkiAAAAM9JREFUKFOd0rENgkAUxvH3CMTGROgcwMLERjfAFVwAayttXAG1YQU3wQ0AjaXGCTxqlHu+w5BcTCh815CQ8Mv/u4AgPKrYr8HFUzDZ5jaBEk9d4hC0kwJBCR7ObVQEmgguPPIj+kXFYBeK6hwvQWMkmW6+QcQxAQy59KVdvcJnviv55UAKMvQ9ZivRHdX1MAVd+xKQauojOAlnjkDTjXS9EN+hyhIfsEq5bMplBVAvDGabUgR2Ye3yv9c21/SmzC5rEVFh88sYtPIeZqZd9AGDHF9KrLBhYAAAAABJRU5ErkJggg==);
	opacity: 1;
	position: absolute;
	right: 13px;
	top: -46px;
	background: #242424;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	line-height: 1.2;
}
.auction-frame-wrapper button.slick-prev {
	left: -52px;
	z-index: 22;
	top: 114px;
}
.auction-frame-wrapper button.slick-prev::before {
	/* display: none; */
	content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAABHNCSVQICAgIfAhkiAAAAM9JREFUKFOd0rENgkAUxvH3CMTGROgcwMLERjfAFVwAayttXAG1YQU3wQ0AjaXGCTxqlHu+w5BcTCh815CQ8Mv/u4AgPKrYr8HFUzDZ5jaBEk9d4hC0kwJBCR7ObVQEmgguPPIj+kXFYBeK6hwvQWMkmW6+QcQxAQy59KVdvcJnviv55UAKMvQ9ZivRHdX1MAVd+xKQauojOAlnjkDTjXS9EN+hyhIfsEq5bMplBVAvDGabUgR2Ye3yv9c21/SmzC5rEVFh88sYtPIeZqZd9AGDHF9KrLBhYAAAAABJRU5ErkJggg==);
	opacity: 1;
	position: absolute;
	left: 40px;
	top: 50px;
	transform: rotate(-181deg);
	margin-top: 0px;
	background: #242424;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	line-height: 1.2;
}
.auction-frame-wrapper {
	background-image: url("../img/auction-back.png")!important;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: auto;
	padding: 2rem 2rem 3rem;
	margin: 4.5rem 2rem;
	position: relative;
}

.auction-frame-wrapper .border-left img {
	position: absolute;
	left: -22px;
	top: -39px;
}

.auction-frame-wrapper .border-right img {
	position: absolute;
	right: -22px;
	top: -39px;
}

.auction-frame-wrapper .centerLogoDv figure img {
	position: absolute;
	left: 0;
	right: 0;
	top: -70px;
	text-align: center;
	margin: 0px auto;
}

.auction_sec ul.auction-items {
	padding: 350px 0 200px;
	margin: 0;
	list-style: none;
	display: flex;
	justify-content: space-between;
}

.auction_sec ul.auction-items .auction-box {
	text-align: center;
	position: relative;
	z-index: 2;
}

.auction_sec ul.auction-items .auction-box .txt h4 {
	color: #ffffff;
	font-size: 12px;
	padding: 11px 0;
}
/* Slider css */
.auction_sec ul.auction-items li:nth-child(1) {
	position: absolute;
	left: -24%;
	bottom: 25%;
}
.auction_sec ul.auction-items li:nth-child(2) {
	position: absolute;
	left: 9%;
	bottom: 27%;
}
.auction_sec ul.auction-items li:nth-child(3) {
	position: absolute;
	left: 19%;
	bottom: 31%;
}
.auction_sec ul.auction-items li:nth-child(4) {
	position: absolute;
	left: 31%;
	bottom: 37%;
}

.auction_sec ul.auction-items li:nth-child(5) {
	position: absolute;
	right: 0;
	left: 0;
	bottom: 30%;
	margin: 0px auto;
	display: table;
}

.auction_sec ul.auction-items .auction-box .img-box {
	background-image: linear-gradient(to right, #000002, #101011, #1a191a, #232323, #2c2c2c);
	background-repeat: no-repeat;
	background-position: left;
	color: #ffe698 !important;
	font-family: "aniron";
	font-size: 10px;
	border: 3px solid;
	border-image-source: linear-gradient(to right, #b88425, #c5973f, #d2a957, #e0bc6f, #edcf88, #efd28b, #f1d58f, #f3d892, #eacb80, #e1bf6f, #d9b25d, #d0a64c) !important;
	border-image-slice: 1;
	border-width: 3px;
	transition: all 0.3s ease-in-out;
	padding: 0px 0px;
	height: 100px;
	width: 85px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0px auto;
}

.auction_sec ul.auction-items li:nth-child(5) .auction-box .img-box {
	width: 190px;
	height: 230px;
}
.auction_sec ul.auction-items .auction-box .img-box img {
	width: 80px;
	height: 100%;
}
.auction_sec ul.auction-items li:nth-child(5) .img-box img {
	height: 100%;
	width: 100%;
}

.auction_sec ul.auction-items li:nth-child(6) {
	position: absolute;
	right: 31%;
	bottom: 34%;
}
.auction_sec ul.auction-items li:nth-child(7) {
	position: absolute;
	right: 19%;
	bottom: 29%;
}

.auction_sec ul.auction-items li:nth-child(8) {
	position: absolute;
	right: -3%;
	bottom: 23%;
}

.auction_sec ul.auction-items li:nth-child(9) {
	position: absolute;
	right: -15%;
	bottom: 16%;
}
/* Slider css ends here */
/*normal css starts here*/
.auction_sec .for-single ul.auction-items li:nth-child(1) {
	position: absolute;
	left: -2% !important;
	bottom: 25% !important;
}
.auction_sec .for-single ul.auction-items li:nth-child(2) {
	position: absolute;
	left: 9% !important;
	bottom: 35% !important;
}
.auction_sec .for-single ul.auction-items li:nth-child(3) {
	position: absolute;
	left: 20% !important;
	bottom: 42% !important;
}
.auction_sec .for-single ul.auction-items li:nth-child(4) {
	position: absolute;
	left: 31% !important;
	bottom: 50% !important;
}

.auction_sec .for-single ul.auction-items li:nth-child(5) {
	position: absolute;
	right: 0;
	left: 0;
	bottom: 30%;
}
.auction_sec .for-single ul.auction-items li:nth-child(6) {
	position: absolute;
	right: 31% !important;
	bottom: 50% !important;
}
.auction_sec .for-single ul.auction-items li:nth-child(7) {
	position: absolute;
	right: 20% !important;
	bottom: 42% !important;
}

.auction_sec .for-single ul.auction-items li:nth-child(8) {
	position: absolute;
	right: 9% !important;
	bottom: 35% !important;
}

.auction_sec .for-single ul.auction-items li:nth-child(9) {
	position: absolute;
	right: -2% !important;
	bottom: 25% !important;
}
/* normal css ends here */
.auction_sec ul.auction-items li:nth-child(5) .txt h4 {
	font-size: 15px;
	line-height: 25px;
}

.auction_sec ul.auction-items li .auction-box:before {
	content: "";
	background: #fbce00;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 13px;
	margin: 0px auto;
	box-shadow: 0px 0px 30px 35px #fbce00;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	z-index: -1;
}

.auction_sec ul.auction-items li:nth-child(5) .auction-box:before {
	content: "";
	background: #fbce00;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 40%;
	margin: 0px auto;
	box-shadow: 0px 0px 60px 85px #fbce00;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	z-index: -1;
}
.player-for-auction{
height: 500px !important;
overflow: scroll !important;
}
.player-for-table-auction {
    height: 454px;
    overflow: scroll;
}
.auction_sec ul.auction-items .auction-box .img-box figure {
	height: 100%;
	margin: 0;
	width: 100%;
}
/*.auction_sec ul.auction-items .auction-box .img-box img {*/
/*	width: 35px;*/
/*	height: 50px;*/
/*}*/

/* Auction Css End Here */


.player-for-auction h4 {
    font-size: 20px!important;
    text-transform: capitalize;
}
.player-head-and-button h3 {
    color: #e3bf4b;
    text-transform: capitalize;
}

.player-auc-button a {
    font-size: 13px;
    border-image: radial-gradient(#fff3de, #B88425) 1;
    width: 100%;
    color: #e3bf4b !important;
    text-transform: capitalize;
    padding: 14px 54px;
}
.player-head-and-button {
    display: flex;
    justify-content: space-between;
}
.player-auc-button {
    display: flex;
}
.player-auction-sec figure {
    margin: 0;
}
.head.player-table-auction h3 {
    font-size: 20px!important;
    color: #e3bf4b;
}
.table-player-bolt-amber h4 {
    font-size: 12px;
    margin: 0;
	color: #e3bf4b !important;
}

.bolt-img-text {
    display: flex;
    align-items: center;
}
.bolt-img-text img {
    margin-right: 10px;
}
.bolt-img-text img {
    border-image: radial-gradient(#fff3de, #B88425) 1 !important;
    border: 1px solid;
}

.table-player-bolt-amber h5 {
    color: #ffffff;
    font-family: 'aniron';
    margin: 0;
}

.bolt-only-img img {
    border: 1px solid #0088FF;
    border-radius: 20px;
	height: 40px;
	width: 40px;
	object-fit: cover;
}
.for-allign-table-center {
    display: flex;
    align-items: center;
}
.for-table-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.for-numbers h5 {
    font-size: 20px !important;
}
.Player-statusDv-bottom-all {
    background-image: linear-gradient(to right, #181718, #101011, #1a191a, #232323, #171617);
    padding: 6px 10px;
    margin: -10px 0.3px -10px;
    border-top-width: 4px !important;
    border-bottom-width: 4px !important;
    border-left-width: 4px;
    border-image: radial-gradient(#fff3de, #B88425) 1;
    border-left-style: solid;
	border-top-style: solid;
	border-bottom-style: solid;
    border-right-width: 4px;
    border-right-style: solid;
    margin-top: 20px;

}

.all-dv-bottom-auc {
    display: flex;
    align-items: center;
    margin: 6px 0px;
}
.all-dv-bottom-auc h5 {
    font-size: 12px !important;
    color: #ffffff;
    margin: 0;
    margin-left: 7px;
}
.all-dv-bottom-auc img {
    border: 1px solid #0088FF;
    border-radius: 30px;
    box-shadow: 0px 0px 10px #0088FF;
}
.all-dv-of-player-bottom-auction {
    display: block;
    justify-content: space-evenly;
}
.all-dv-bottom-auc img {
    width: 50px;
    height: 50px;
	object-fit: contain;
}
.all-dv-bottom-box-shadow img {
    box-shadow: 0px 0px 10px #707070 !important;
    border: 1px solid #707070 !important;
}

.all-dv-bottom-box-shadow2 img {
    box-shadow: 0px 0px 10px #A45607 !important;
    border: 1px solid #A45607 !important;
}
.auction-bottom-btn a {
    width: 35% !important;
    /* display: flex; */
}
.auction-bottom-btn {
    display: flex;
    justify-content: end;
}

.btn_bg_custome_golden{
    background: #e3bf4b !important;
    border: 1px solid black !important;
    color: black;
}