.character {
  padding-top: 100px;
  padding-bottom: 100px;
}
.image-uploader .upload-text i {
  display: none;
}
.character-form-wrapper .image-uploader {
  border-style: solid;
  border-width: 3px;
  border-image-source: linear-gradient(
    to right,
    #b88425,
    #c5973f,
    #d2a957,
    #e0bc6f,
    #edcf88,
    #efd28b,
    #f1d58f,
    #f3d892,
    #eacb80,
    #e1bf6f,
    #d9b25d,
    #d0a64c
  ) !important;
  border-image-slice: 1;
  background-image: url("../img/upload.png");
  background-size: cover;
}
.character-form-wrapper input::placeholder,
.character-form-wrapper textarea::placeholder {
  color: #fff !important;
  font-family: "montserrat" !important;
}
.character-form-wrapper input,
.character-form-wrapper textarea {
  background-image: linear-gradient(
    to right bottom,
    #000002,
    #101011,
    #1a191a,
    #232323,
    #2c2c2c
  );
  border-radius: 0px;
  color: #e3bf4b !important;
  font-family: "aniron";
  font-size: 10px;
  padding: 10px;
  border: 0px solid;
  border-style: solid;
  border-width: 2px;
  border-image-source: linear-gradient(
    to right,
    #b88425,
    #c5973f,
    #d2a957,
    #e0bc6f,
    #edcf88,
    #efd28b,
    #f1d58f,
    #f3d892,
    #eacb80,
    #e1bf6f,
    #d9b25d,
    #d0a64c
  ) !important;
  border-image-slice: 1;
}

.character-form-wrapper button.btn {
  background-image: linear-gradient(
    to right bottom,
    #000002,
    #101011,
    #1a191a,
    #232323,
    #2c2c2c
  );
  border-radius: 0px;
  color: #e3bf4b !important;
  font-family: "aniron";
  font-size: 12px;
  padding: 11px 20px;
  border: 0px solid;
  border-style: solid;
  border-width: 2px;
  border-image-source: linear-gradient(
    to right,
    #b88425,
    #c5973f,
    #d2a957,
    #e0bc6f,
    #edcf88,
    #efd28b,
    #f1d58f,
    #f3d892,
    #eacb80,
    #e1bf6f,
    #d9b25d,
    #d0a64c
  ) !important;
  border-image-slice: 1;
}
.character-form-wrapper {
  padding-top: 50px;
}
.upload-text {
  justify-content: end !important;
  padding: 10px;
}
.character-form-wrapper .image-uploader .upload-text span {
  color: #cccccc;
  font-size: 15px;
  font-family: "montserrat";
}
.ammo label {
  display: block;
  color: #e3bf4b;
  font-family: "aniron";
  font-size: 10px;
}
.ammo input.sm {
  display: inline-block;
  width: 17%;
}
.ammo label {
  display: block;
}
.frame-wrapper {
  /*background-image: url("../img/character-bg.png") !important;*/
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 3rem 2rem;
  margin: 4.5rem 2rem;
  position: relative;
}
.frame-wrapper .centerLogoDv figure img {
  position: absolute;
  left: 0;
  right: 0;
  top: -70px;
  text-align: center;
  margin: 0px auto;
}
.frame-wrapper .border-left img {
  position: absolute;
  left: -22px;
  top: -39px;
}
.frame-wrapper .border-right img {
  position: absolute;
  right: -22px;
  top: -39px;
}
.character-wrapper.active {
  border: 3px solid #cfa550;
}
.character-wrapper {
  position: relative;
  border-radius: 9px;
  margin-bottom: 10px;
}
.character-wrapper figure {
  margin: 0px;
}
.character-list {
  margin-bottom: 42px;
}
.character-wrapper .char-name {
  position: absolute;
  left: 9px;
  top: 33%;
}
.character-wrapper img.char-img {
  filter: brightness(0.5);
  width: 100%;
  height: 70px;
  object-fit: cover;
  object-position: right;
}
.character-form-wrapper label {
  color: #e3bf4b;
  font-family: "aniron";
}
.characterModal .main-charater-img img {
  height: 400px;
}
/* character-sec css ends here */
