.modal-dialog {
    max-width: 800px;
    margin: 30px auto;
    background: url(../img/welcome-wrapper.png);
    background-color: transparent;
    background-size: 100% 100%;
}
.modal-content {
    background: transparent;
    border: none;
}
 .modal-dialog {
    /*height: 455px;*/
}
.modal-dialog .modal-header {
    margin-top: 50px;
    border:  none;
    justify-content: center;
}
.modal-dialog .modal-footer,
.modal-dialog .modal-body {
    border:  none!important;
}
.modal-body .counter-wrapper {
    text-align: center;
    margin-top: 20px;
}

.modal-dialog h1 ,
.modal-dialog h2 ,
.modal-dialog h5 ,
.modal-dialog h6 ,
.modal-dialog h4 {
    color: #E3BF4B;
}

.modal-dialog h3 {
    color: #E3BF4B;
    font-size: 22px;
}
 .modal-dialog .modal-title {
    color: #E3BF4B;
     text-align: center;
    font-size: 22px;
    font-family: 'montserrat';
}
.modal-dialog .counter span {
    font-size: 22px;
    font-family: 'aniron';
    color: #fff;
}
.guild-character-modal .modal-footer {
    padding-bottom: 50px;
}
.guilds-character {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.guilds-character .characterBox {
    flex: 0 0 16%;
}
.guilds-character label img {
    width: 100px;
    height: 140px;
    object-fit: fill;
}
/* .characterEnhance-modal select {
    border-top-width: 4px !important;
    border-bottom-width: 4px !important;
    border-left-width: 4px;
    border-image: radial-gradient(#fff3de, #B88425) 1;
    border-left-style: solid;
    border-top-style: solid;
    background-image: linear-gradient(to right, #181718, #101011, #1a191a, #232323, #171617);
    border-bottom-style: solid;
    border-right-width: 4px;
    border-right-style: solid;
    color: #fff;
}
.characterEnhance-modal select option {
    background: #000;
    color: #fff;
} */