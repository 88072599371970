/* Crafting Css Start Here */
.frame-wrapper {
    background-image: url('../img/craft-back.png') !important;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: auto;
    padding: 2rem 2rem 3rem;
    margin: 4.5rem 2rem;
    position: relative;
}

.frame-wrapper .border-left img {
    position: absolute;
    left: -22px;
    top: -39px;
}

.frame-wrapper .border-right img {
    position: absolute;
    right: -22px;
    top: -39px;
}

.frame-wrapper .centerLogoDv figure img {
    position: absolute;
    left: 0;
    right: 0;
    top: -70px;
    text-align: center;
    margin: 0px auto;
}

.crafting_sec {
    padding: 40px 0 100px;
}

.crafting_sec .heading-wrapper {
    text-align: center;
}

.crafting_sec .heading-wrapper h4 {
    color: #E3BF4B;
    font-size: 20px;
    padding-right: 20px;
}

.crafting_sec .all-crafting-wrapper ul {
    display: block;
    align-items: end;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
}

.crafting_sec .all-crafting-wrapper .items {
    /*display: flex;*/
    /*align-items: end;*/
    /*padding: 0;*/
    /*margin: 0;*/
    /*flex-wrap: wrap;*/
    /*justify-content: center;*/
}

.crafting_sec .all-crafting-wrapper .items div.craft {
    /*width: auto;*/
    /*list-style: none;*/
    background-image: url('../img/craft-shade.png');
    border-style: solid;
    border-width: 2px;
    border-image-source: linear-gradient( to right, #b88425, #c5973f, #d2a957, #e0bc6f, #edcf88, #efd28b, #f1d58f, #f3d892, #eacb80, #e1bf6f, #d9b25d, #d0a64c ) !important;
    /* height: 190px; */
    border-image-slice: 1;
    background-size: cover;
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    /*margin: 0px 5px 15px;*/
    /*flex: 0 0 10%;*/
}

.crafting_sec .all-crafting-wrapper ul li figure img {
    height: 140px;
    width: 100px;
}

.crafting_sec .all-crafting-wrapper ul li .img-box {
    position: relative;
    margin-bottom: 5px;
    text-align: center;
    padding: 5px 0px;
}

.crafting_sec .all-crafting-wrapper ul li .img-box .shade {
    position: absolute;
    left: 0px;
    bottom: -17px;
}

.crafting_sec .all-crafting-wrapper ul li .img-box figure {
    position: relative;
    z-index: 2;
}

.crafting_sec .all-crafting-wrapper ul li .name h4 {
    color: #E3BF4B;
    font-size: 14px;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.crafting_sec .all-crafting-wrapper {
    padding-top: 30px;
}

/* Crafting Css End Here */