::-webkit-scrollbar {
  width: 0;
}
button {
  font-size: 11px;
  font-family: "aniron";
}
h3 {
  font-size: 16px;
  font-family: "aniron";
}
h4 {
  font-size: 12px;
  font-family: "aniron";
}
h5 {
  font-size: 8px;
  font-family: "aniron";
}
figure {
  margin-bottom: 0;
}
/*.frame-wrapper {*/
/*  background-image: url("../img/inventorybg.png") !important;*/
/*  background-size: 100% 100%;*/
/*  background-repeat: no-repeat;*/
/*  height: auto;*/
/*  padding: 5rem 2rem 5rem;*/
/*  margin: 4.5rem 2rem;*/
/*  position: relative;*/
/*}*/
.frame-wrapper .border-left img {
  position: absolute;
  left: -22px;
  top: -39px;
}
.frame-wrapper .border-right img {
  position: absolute;
  right: -22px;
  top: -39px;
}
.frame-wrapper .centerLogoDv figure img {
  position: absolute;
  left: 0;
  right: 0;
  top: -70px;
  text-align: center;
  margin: 0px auto;
}
/* Box One Css Start Here */
/* Inventory Css Start Here */
.invent-sec{
    padding-top: 60px;
    padding-bottom: 60px;
}
.inventory-selection .col-1 {
    padding: 0px 9px;
}
.inventroyPage .main-content .inventroy-Box .beforeImg {
  position: absolute;
  right: -10px;
  z-index: 1;
  top: 20px;
}
.inventroyPage .main-content .inventroy-Box .inventories ul.inventory-links {
  padding: 0;
  margin-bottom: 10px;
  list-style: none;
  margin-top: 20px;
}
.inventroyPage .main-content .inventroy-Box .inventories ul.inventory-links li a {
  font-size: 12px;
  line-height: 28px;
  color: #e3bf4b;
  font-family: "aniron";
  opacity: 0.6;
}
.inventroyPage .main-content .inventroy-Box .inventories ul.inventory-links li a.active {
  opacity: 1;
}
.inventroyPage .main-content .inventroy-Box .title {
  padding-bottom: 20px;
}
.inventroyPage .main-content .inventroy-Box .inventories ul.inventory-links li a {
    padding: 5px;
}
.inventory-list {
  border-image: radial-gradient(#fff3de, #B88425) 1;
  border-left-style: solid;
  border-right-width: 4px;
  border-right-style: solid;
  border-left-width: 4px;
  margin-top: -12px;
  margin-bottom: -12px;
}
.inventory-list .inventory-links {
  height: 430px;
  overflow-y: scroll;
}
.inventory-list .btn {
  margin: 20px 10px;
  border: 0;
}
.nav-pills .nav-link.active{
    background: transparent !important;
}
.inventroyPage .main-content .inventroy-Box .search-Box form .form-group input {
  background-image: url(../img/searchicon.png) !important;
  font-size: 9px;
  background-repeat: no-repeat !important;
  background-position: left center !important;
  background-position-x: 5px !important;
  font-family: "Poppins";
  color: #fff;
  border-radius: 0;
  background: transparent;
  border-image-source: linear-gradient(
    to right,
    #b88425,
    #c5973f,
    #d2a957,
    #e0bc6f,
    #edcf88,
    #efd28b,
    #f1d58f,
    #f3d892,
    #eacb80,
    #e1bf6f,
    #d9b25d,
    #d0a64c
  ) !important;
  border-image-slice: 1;
  border-width: 2px;
  padding-left: 25px;
}
.inventroyPage .main-content .inventroy-Box .search-Box form .form-group input::placeholder {
  color: #fff;
}
.inventroyPage .main-content .inventroy-Box .inventory-itemBox1 {
  text-align: center;
  border-style: solid;
  border-width: 2px;
  /*border-image-source: linear-gradient(*/
  /*  to right,*/
  /*  #b88425,*/
  /*  #c5973f,*/
  /*  #d2a957,*/
  /*  #e0bc6f,*/
  /*  #edcf88,*/
  /*  #efd28b,*/
  /*  #f1d58f,*/
  /*  #f3d892,*/
  /*  #eacb80,*/
  /*  #e1bf6f,*/
  /*  #d9b25d,*/
  /*  #d0a64c*/
  /*) !important;*/
  height: 70px;
  width: 100%;
  border-image-slice: 1;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.inventroyPage .main-content .inventroy-Box .inventory-itemBox1 .count {
  position: absolute;
  bottom: 0;
  right: 0;
  background: linear-gradient(
    to right,
    #b88425,
    #c5973f,
    #d2a957,
    #e0bc6f,
    #edcf88,
    #efd28b,
    #f1d58f,
    #f3d892,
    #eacb80,
    #e1bf6f,
    #d9b25d,
    #d0a64c
  ) !important;
  font-family: "Poppins";
  font-weight: 800;
  border-radius: 7px 0 0 0;
  height: 20px;
  line-height: 20px;
  /* width: 18px; */
  width: 100%;
}

.inventroyPage .main-content .inventroy-Box .inventory-itemBox1 .count span {
  color: #000;
  font-size: 7px;
  font-family: "Poppins";
  font-weight: 800;
}
.inventroyPage .main-content .inventroy-Box .inventory-itemBox1 .count span.large {
  font-size: 12px;
}

.inventroyPage .main-content .inventroy-Box .nextBtn {
  text-align: right;
}
.inventroyPage .main-content .inventroy-Box .search-Box form .form-group {
  background-image: linear-gradient(to left bottom, #000002, #101011, #1a191a, #232323, #2c2c2c);
}
/* Inventory Css End Here */
.main-content .inventroy-Box {
  background-image: none !important;
  padding: 0px !important;
  margin: 0px !important;
  border-left-width: 0px !important;
  border-image: none !important;
  border-left-style: none !important;
  border-right-width: 0px !important;
  border-right-style: none !important;
  height: unset !important;
  overflow-x: unset !important;
}
/*.inventory-selection .inventory-itemBox1 img {*/
/*  height: 41px;*/
/*  margin-top: 15px;*/
/*}*/
.inventory-selection .inventory-itemBox1 img {
  height: 125px;
  margin-top: 15px;
  width: 100%;
  object-fit: cover;
}
/* no img css starts here*/
section.invent-sec .inventroy-Box.no-img {
  background: none !important;
  border: none !important;
}
.inventroyPage .inventoryBgImage{
  background-image: url('../img/inventorybg.png') !important;
}
/* no img css ends here*/
.detail-wrap p {
  font-size: 10px;
  color: #fff;
  margin: 0px;
}

.detail-wrap {
  background: linear-gradient(      to right bottom,      #000002,      #101011,      #1a191a,      #232323,      #2c2c2c    ) !important;
  padding: 5px;
  border: 1px solid #ecd436;
}
.detail-wrap ul {
  text-decoration: none;
  font-size: 10px;
  color: #fff;
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.detail-wrap p {
  font-size: 10px;
  color: #fff;
  margin: 0px;
}
.inventory-selection a {
  text-decoration: none;
}

.detail-wrap {
  background: linear-gradient(      to right bottom,      #000002,      #101011,      #1a191a,      #232323,      #2c2c2c    ) !important;
  padding: 5px;
  border: 1px solid #ecd436;
  z-index: 6;
}

.detail-wrap ul li {
  margin: 0 !important;
  justify-content: space-between !important;
  border: 0 !important;
  background: none !important;
}

.detail-wrap ul span.head {
  text-transform: capitalize;
  color: #e3c172;
  margin-right: 7px;
  text-decoration: none;
}
.detail-wrap ul span.head:hover{
  text-decoration: none;
}
.detail-wrap h5 {
  font-size: 14px;
  color: #fff;
  margin-top: 7px;
  text-decoration: none;
}