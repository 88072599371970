:root {
    --ItemBorder : linear-gradient(to right, rgb(184, 132, 37), rgb(197, 151, 63), rgb(210, 169, 87), rgb(224, 188, 111), rgb(237, 207, 136), rgb(239, 210, 139), rgb(241, 213, 143), rgb(243, 216, 146), rgb(234, 203, 128), rgb(225, 191, 111), rgb(217, 178, 93), rgb(208, 166, 76));
}
.char-details .Player-statusDv img {
    height: 140px;
    width: 100px;
    object-fit: fill;
    object-position: top;
    margin: 0px auto;
    display: table;
}
.characterEnhance-modal .box {
    text-align: center;
    border-style: solid;
    border-width: 2px;
    /*border-image-source: linear-gradient(to right, rgb(184, 132, 37), rgb(197, 151, 63), rgb(210, 169, 87), rgb(224, 188, 111), rgb(237, 207, 136), rgb(239, 210, 139), rgb(241, 213, 143), rgb(243, 216, 146), rgb(234, 203, 128), rgb(225, 191, 111), rgb(217, 178, 93), rgb(208, 166, 76));*/
    height: 130px !important;
    width: 130px !important;
    border-image-slice: 1;
    margin-bottom: 10px ;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    margin-top: 30px !important;
}
.characterEnhance-modal .box img{
    height: 130px !important;
    width: 130px !important;
    object-fit: cover !important;
}
.characterEnhance-modal .box figure{
    margin: 0px;
}
.characterEnhance-modal .box .count {
    position: absolute;
    bottom: 0px;
    right: 0px;
    position: absolute;
    bottom: 0;
    right: 0;
    background: linear-gradient( to right, #b88425, #c5973f, #d2a957, #e0bc6f, #edcf88, #efd28b, #f1d58f, #f3d892, #eacb80, #e1bf6f, #d9b25d, #d0a64c ) !important;
    font-family: "Poppins";
    font-weight: 800;
    border-radius: 7px 0 0 0;
    height: 20px;
    line-height: 20px;
    width: 18px;
}