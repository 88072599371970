/* Castle Css Start Here */
.frame-wrapper-p {
	/*background-image: url("../img/crastle-back.png") !important;*/
	background-size: 100% 100%;
	background-repeat: no-repeat;
	padding: 3rem 2rem;
	margin: 4.5rem 2rem;
	position: relative;
}

.frame-wrapper-p .centerLogoDv figure img {
	position: absolute;
	left: 0;
	right: 0;
	top: -70px;
	text-align: center;
	margin: 0px auto;
}

.frame-wrapper-p .border-left img {
	position: absolute;
	left: -22px;
	top: -39px;
}

.frame-wrapper-p .border-right img {
	position: absolute;
	right: -22px;
	top: -39px;
}

.castle_sec {
	padding: 40px 0 250px;
}

.castle_sec .heading_wrapper {
	text-align: center;
	padding-top: 100px;
}

/* Castle Css End Here */

.castle_sec .box {
	/*margin-bottom: 50px;*/
}
.castle_sec .box .img-box {
	list-style: none;
	border-style: solid;
	border-width: 2px;
	border-image: radial-gradient(#fff3de, #b88425) 1;
}
.castle_sec .box .img-box figure {
	width: 100%;
	height: 14rem;
	padding: 30px;
}
.castle_sec .box .img-box img {
	width: 100%;
	height: 100%;
}
.castle_sec .box .txt {
	margin-top: 20px;
}
.castle_sec .box .txt h4 {
	margin-bottom: 11px;
	color: #e3bf4b;
	font-size: 11px;
}
.castle_sec .box .txt h4 span {
	color: #fff;
	margin-left: 7px;
}