ul.classes-list .img-box img {
    filter: brightness(0.5);
}

.frame-wrapper .nav-menu ul.menus {
    padding-top: 25px;
}
/*.frame-wrapper {*/
/*    background-image: url('../img/profileback.png') !important;*/
/*    background-size: 100% 100%;*/
/*    background-repeat: no-repeat;*/
/*    height: auto;*/
/*    padding: 2rem 2rem 3rem;*/
/*    margin: 4.5rem 2rem;*/
/*    position: relative;*/
/*}*/
.frame-wrapper .border-left img {
    position: absolute;
    left: -22px;
    top: -39px;
}
.frame-wrapper .border-right img {
    position: absolute;
    right: -22px;
    top: -39px;
}
.frame-wrapper .centerLogoDv figure img {
    position: absolute;
    left: 0;
    right: 0;
    top: -70px;
    text-align: center;
    margin: 0px auto;
}

/* Profile Css Start Here */
.ProfileContent-Dv {
    padding: 40px 0 100px;
}
.ProfileContent-Dv .classesDv ul.classes-list {
    padding: 0;
    margin: 0;
    list-style: none;
}
.ProfileContent-Dv .classesDv {
    padding-left: 40px;
}
.ProfileContent-Dv .classesDv  .head {
    padding-bottom: 20px;
}
.ProfileContent-Dv .classesDv ul.classes-list li {
    position: relative;
    margin-bottom: 20px;
}
.ProfileContent-Dv .classesDv ul.classes-list li .img-box figure img {
    width: 100%;
    height: 80px;
    object-fit: cover;
    object-position: top;
}
.ProfileContent-Dv .classesDv ul.classes-list li button {
    width: 100%;
    background: transparent !important;
}
.ProfileContent-Dv .classesDv ul.classes-list li .img-box figure img {
    width: 100%;
    height: 70px;
    object-fit: cover;
    border-radius: 10px;
}
.ProfileContent-Dv .classesDv ul.classes-list li .classText {
    position: absolute;
    top: 12px;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0px 10px;
}
.ProfileContent-Dv .actionLinks {
    display: flex;
    align-items: center;
    padding-left: 40px;
}
.ProfileContent-Dv  .actionLinks a {
    color: #E3BF4B;
    font-family: 'aniron';
    font-size: 11px;
}
.ProfileContent-Dv .actionLinks .spacer {
    background: #FFE799;
    width: 2px;
    height: 10px;
    margin: 0px 20px;
}
/* Warrior Dv Css Start Here */
.ProfileContent-Dv .Warrior-Dv .head {
    padding-bottom: 10px;
}
.ProfileContent-Dv .Warrior-Dv .characterKills {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
}
.ProfileContent-Dv .Warrior-Dv .characterKills .property h4 {
    color: #E3BF4B;
    font-size: 14px;
    padding-right: 20px;
}
.ProfileContent-Dv .Warrior-Dv .characterKills  .value h3 {
    color: #fff;
}
.ProfileContent-Dv .Warrior-Dv  p {
    color: #fff;
    font-size: 13px;
    font-weight: 100;
}
.ProfileContent-Dv .Warrior-Dv ul.awardsList {
    padding: 0;
    margin: 0;
    list-style: none;
}
.ProfileContent-Dv .Warrior-Dv ul.awardsList li {
    display: inline-block;
}
.ProfileContent-Dv .Warrior-Dv ul.awardsList li .imgBox-award {
    border-style: solid;
    border-width: 2px;
    border-image: radial-gradient(#fff3de, #B88425) 1;
    width: 50px;
    margin: auto 2px;
    position: relative;
    height: 60px;
    overflow: hidden;
    text-align: center;
    margin-bottom: 10px;
    cursor: pointer;
}
.ProfileContent-Dv .vertical-line {
    height: 68%;
    width: 2px;
    background: #E3BF4B;
    position: absolute;
    right: 0;
    left: 0;
    margin: 0px auto;
    top: 9rem;
}
/* Warrior Dv Css End Here */

/* Character Css Start Here */
.ProfileContent-Dv .CharacterDv {
    padding-top: 0;
    position: relative;
}
.ProfileContent-Dv .CharacterDv ul.AboutPlayers {
    padding: 0;
    margin: 0;
    list-style: none;
    display: block;
    width: 100%;
    position: relative;
}
.ProfileContent-Dv .CharacterDv ul.AboutPlayers li.playersCharacter {
    width: 26%;
    display: inline-block !important;
    margin-right: 20px;
    position: relative;
    margin: 10px 5px;
}
section.profile-frame-section .row {
    display: flex;
    align-items: center;
}
ul.AboutPlayers .player-img figure {
    margin: 0px;
}
.ProfileContent-Dv .CharacterDv ul.AboutPlayers li .player-img {
    align-items: center;
    background: #2f3130;
    border-image: radial-gradient(#fff3de,#b88425) 1;
    border-style: solid;
    border-width: 7px;
    cursor: pointer;
    display: flex;
    height: 100px;
    justify-content: center;
    margin-bottom: 10px;
    margin-right: 10px;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 100%;
}
/* .player-img-of-first-li {
    position: absolute;
    bottom: 28px;
} */
.ProfileContent-Dv .CharacterDv ul.AboutPlayers li .content {
    width: 100%;
    /* position: relative; */
    /* overflow: hidden; */
    /* text-align: center; */
    /* margin-bottom: 10px; */
    cursor: pointer;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    position: relative;
    bottom: 31px;
    left: 0px;
}

.player-img-of-first-li img {
    background-color: #2f3130;
    border-image: radial-gradient(#fff3de,#b88425) 1;
    border-style: solid;
    border-width: 4px;
    height: 100px;
    object-fit: contain;
    padding: 5px;
    width: 80px;
}

.ProfileContent-Dv .CharacterDv ul.AboutPlayers li .content .Fornew h6 {
    font-size: 10px;
    color: #E3BF4B;
    line-height: 16px;
    text-align: left;
    font-family: 'aniron';
}
.ProfileContent-Dv .CharacterDv ul.AboutPlayers li  .content .playerName h5 {
    color: #E3BF4B;
    margin-bottom: 0;
}
.ProfileContent-Dv .CharacterDv ul.AboutPlayers li .content .playerName span {
    font-size: 15px;
    color: #fff;
}
.ProfileContent-Dv .CharacterDv ul.AboutPlayers li .content .playerName {
    /*margin-bottom: 5px;*/
}
.ProfileContent-Dv .CharacterDv ul.AboutPlayers li .content .playerLevel h5 {
    color: #E3BF4B;
    margin-bottom: 5px;
}
.ProfileContent-Dv .CharacterDv ul.AboutPlayers li .content .playerLevel span {
    font-size: 10px;
    color: #fff;
}
.ProfileContent-Dv .CharacterDv ul.AboutPlayers li .content .Fornew {
    text-align: center;
}
.ProfileContent-Dv .CharacterDv ul.AboutPlayers li .content .Fornew h6 {
    color: #E3BF4B;
    line-height: 16px;
    text-align: center;
}
/* Character Css End Here */

/* Guilds Css Start Here */
.ProfileContent-Dv .GuildsDv .head {
    padding-bottom: 20px;
}
.ProfileContent-Dv .GuildsDv .head h3 {
    font-size: 19px;    
    color: #E3BF4B;
}
.heading-all H3 {
    font-size: 26px;
    padding: 10px 0px;
    color: #E3BF4B;
}
.ProfileContent-Dv .GuildsDv .discoo {
    display: block;
    align-items: center;
    margin-bottom: 30px;
}
.ProfileContent-Dv .GuildsDv .discoo .imgBox {
    border-style: solid;
    border-width: 4px;
    border-image: radial-gradient(#fff3de, #B88425) 1;
    width: auto;
    position: relative;
    height: 100px;
    overflow: hidden;
    text-align: center;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2f3130;
    width: 100px;
}

.for-lef-border {
    border-left: 3px solid #E3BF4B;
}
.ProfileContent-Dv .GuildsDv .discoo .contentDv {
    padding-left: 0px;
    padding: 14px 0px;
}
.ProfileContent-Dv .GuildsDv .discoo .contentDv h3.name {
    color: #E3BF4B;
}
.ProfileContent-Dv .GuildsDv .discoo .contentDv span {
    color: #fff;
    font-size: 15px;
}
.GuildsDv {
    padding: 0px 16px;
}
.ProfileContent-Dv .GuildsDv .discoo .contentDv ul.about {
    margin: 0;
    padding: 0;
    display: block;
    align-items: center;
    padding-top: 3px;
}
.ProfileContent-Dv .GuildsDv .discoo .contentDv ul.about li {
    font-size: 14px;
    color: #E3BF4B;
    list-style: none;
    margin-right: 15px;
}
.ProfileContent-Dv .CareerDv .Awardss ul.awardsBox .player-img-of-first-li img {
    border-width: 0px;
    padding: 0px;
    object-fit: contain;
    object-position: top center;
    margin-top: 3px;
}
.ProfileContent-Dv .GuildsDv .discoo .contentDv ul.about li ruby {
    color: #fff;
}
/* Guilds Css End Here */

/* Carrier Kill Css Start Here */
.ProfileContent-Dv .CareerDv {
    margin-top: -4rem;
}
.ProfileContent-Dv .CareerDv .career-kills {
    display: flex;
    align-items: center;
    padding-bottom: 50px;
}
.ProfileContent-Dv .CareerDv .career-kills h4 {
    color: #E3BF4B;
    font-size: 14px;
    padding-right: 20px;
}
.ProfileContent-Dv .CareerDv .career-kills h3 {
    color: #fff;
}
.ProfileContent-Dv .CareerDv  .balance {
    padding-bottom: 20px;
}
.ProfileContent-Dv .CareerDv .balance h3 {
    font-size: 19px;
    color: #E3BF4B;
    padding-bottom: 20px;
}
.ProfileContent-Dv .CareerDv .balance  .coins .coinflex {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 5px;
}
.ProfileContent-Dv .CareerDv .balance .coins .coinflex h4.txt {
    color: #FFFFFF;
    font-family: 'Poppins';
    font-size: 10px;
    flex : 0 0 30%
}
.ProfileContent-Dv .CareerDv .balance .coins .coinflex .for-img {
    flex : 0 0 10%
}
.ProfileContent-Dv .CareerDv .balance .coins .coinflex h4 {
    font-size: 14px;
    color: #E3BF4B;
    flex : 0 0 60%
}
.ProfileContent-Dv .CareerDv .Awardss h3 {
    font-size: 18px;
    color: #E3BF4B;
    padding-bottom: 20px;
}
.ProfileContent-Dv .CareerDv .Awardss ul.awardsBox {
    padding: 0;
    margin: 0;
}
.ProfileContent-Dv .CareerDv .Awardss ul.awardsBox li {
    width: 100px;
    height: 120px;
    list-style: none;
    border-style: solid;
    border-width: 4px;
    border-image: radial-gradient(#fff3de, #B88425) 1;
    /*width: auto;*/
    position: relative;
    overflow: hidden;
    text-align: center;
    margin-bottom: 10px;
    cursor: pointer;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to right,#000002,#101011,#1a191a,#232323,#2c2c2c);
    display: inline-block;
}
.awardsBox .imgBox-award figure img {
    width: 80px;
    height: 120px;
    object-fit: unset;
}
.ProfileContent-Dv .CareerDv  .For-weapons {
    padding-top: 15px;
}
.ProfileContent-Dv .CareerDv .For-weapons h3 {
    color: #E3BF4B;
    padding-bottom: 15px;
}
.ProfileContent-Dv .CareerDv .For-weapons ul.weaponList {
    padding: 0;
    margin: 0;
    display: flex;
    list-style: none;
}
.ProfileContent-Dv .CareerDv .For-weapons ul.weaponList li {
    border-style: solid;
    border-width: 2px;
    border-image: radial-gradient(#fff3de, #B88425) 1;
    width: 60px;
    position: relative;
    height: 60px;
    overflow: hidden;
    text-align: center;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to right,#000002,#101011,#1a191a,#232323,#2c2c2c);
    margin-right: 5px;
}
.ProfileContent-Dv .CareerDv .For-weapons ul.weaponList li .checked {
    position: absolute;
    right: 5px;
    top: 0;
}
.ProfileContent-Dv .CareerDv .PetsDv {
    padding-top: 30px;
}
.ProfileContent-Dv .CareerDv .PetsDv h3 {
    color: #E3BF4B;
    padding-bottom: 10px;
}
.ProfileContent-Dv .CareerDv .PetsDv ul.petsList {
    padding: 0;
    margin-bottom: 0;
    list-style: none;
    display: flex;
}
.ProfileContent-Dv .CareerDv .PetsDv ul.petsList li .imgBox {
    border-style: solid;
    border-width: 2px;
    border-image: radial-gradient(#fff3de, #B88425) 1;
    width: 60px;
    position: relative;
    height: 60px;
    overflow: hidden;
    text-align: center;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to right,#000002,#101011,#1a191a,#232323,#2c2c2c);
    margin-right: 5px;
}
.ProfileContent-Dv .CareerDv .PetsDv ul.petsList li .imgBox figure img {
    width: 50px;
    height: 50px;
}
.ProfileContent-Dv .CharacterDv ul.AboutPlayers li .player-img img {
    height: 22px;
    width: 100%;
    object-fit: cover;
    position: relative;
    /* left: 0; */
    /* right: 0; */
    /* bottom: 0; */
    /* top: 0; */
    /* display: flex; */
    bottom: 27px;
}
.playerLevel span {
    color: #ffffff;
}
.content-of-first-li {
    /* margin-top: 70px; */
    /* position: relative;
    top: 10px; */
}
.content-of-first-li .playerName {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.two-span {
    display: flex;
    padding: 10px 0px;
    justify-content: space-between;
    align-items: center;
}

.content-of-first-li .playerName {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ProfileContent-Dv .GuildsDv .discoo .imgBox figure {
    margin: 0;
}

.two-span .bankLft {
    display: flex;
    align-items: center;
    flex: 0 0 65%;
    justify-content: space-between;
}

.two-span .bankLft .for-img {
    text-align: end;
}

.two-span .bankLft .for-img img {
    min-width: 55px;
}
.two-span h4 {
    font-size: 15px;
    color: #ffffff;
    font-family: 'aniron';
    text-transform: capitalize;
}
.two-span h4 span {
    color: #e3bf4b;
    padding-left: 20px;
}
.imgBox-award {
    border: 1px solid #000;
    padding: 7px;
}
/* Carrier Kill Css End Here */

/* Profile Css End Here */