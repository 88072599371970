/*.frame-wrapper {*/
/*    !*background-image: url("../img/playbg.png");*!*/
/*    background-size: 100% 100%;*/
/*    background-repeat: no-repeat;*/
/*    padding: 3rem 2rem;*/
/*    margin: 4.5rem 2rem;*/
/*    position: relative;*/
/*  }*/
  .frame-wrapper .centerLogoDv figure img {
    position: absolute;
    left: 0;
    right: 0;
    top: -70px;
    text-align: center;
    margin: 0px auto;
  }
  .frame-wrapper .border-left img {
    position: absolute;
    left: -22px;
    top: -39px;
  }
  .frame-wrapper .border-right img {
    position: absolute;
    right: -22px;
    top: -39px;
  }
  .playrow {
    padding-bottom: 150px;
    padding-top: 80px;
}
  .character-wrapper.active{
      border: 3px solid #cfa550;
  }
  .character-wrapper {
    position: relative;
    border-radius: 9px;
    margin-bottom: 10px;
  }
  .character-wrapper figure {
    margin: 0px;
  }
  .character-wrapper .char-name {
    position: absolute;
    left: 9px;
    top: 33%;
  }
 
  .progress {
    background-color: #2e2e2e;
    height: 0.5rem !important;
    border-radius: 0px !important;
  }
  .golden-bar {
    background-image: linear-gradient(
      to right,
      #b88425,
      #c5973f,
      #d2a957,
      #e0bc6f,
      #edcf88,
      #efd28b,
      #f1d58f,
      #f3d892,
      #eacb80,
      #e1bf6f,
      #d9b25d,
      #d0a64c
    ) !important;
  }
  .character-specification .spec-name-and-percent {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10px;
  }
  .spec-name-and-percent h6 {
    color: #ffe799;
    font-family: "aniron";
    font-weight: bold;
    font-size: 10px;
    margin-bottom: 0px;
  }
  .spec-name-and-percent h5 {
    color: #ffe799;
    font-family: "aniron";
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 0px;
  }
  .character-progress {
    margin: 20px 0px;
  }
  .character-specification {
    margin-top: 45px;
  }
  a.btn-theme {
    background-image: linear-gradient(
      to right,
      #000002,
      #101011,
      #1a191a,
      #232323,
      #2c2c2c
    );
    background-repeat: no-repeat;
    background-size: 130%;
    background-position: left;
    padding: 15px 70px;
    color: #ffe698 !important;
    font-family: "aniron";
    font-size: 15px;
    border: 3px solid;
    border-image-source: linear-gradient(
      to right,
      #b88425,
      #c5973f,
      #d2a957,
      #e0bc6f,
      #edcf88,
      #efd28b,
      #f1d58f,
      #f3d892,
      #eacb80,
      #e1bf6f,
      #d9b25d,
      #d0a64c
    ) !important;
    border-image-slice: 1;
    border-width: 3px;
    transition: all 0.3s ease-in-out;
  }
  
  /* character-sec css start here */
  .character{
      padding-top: 60px;
      padding-bottom: 60px;
  }
  .character p.char-des {
      color: #fff;
      font-weight: 300;
  }
  .character a.cta {
      text-decoration: underline;
      font-family: 'aniron';
      font-size: 11px;
  }
  .character .character-wrapper {
      margin: 30px 0px;
  }
  .cta-row{
      padding-top: 80px;
  }
  /* character-sec css ends here */