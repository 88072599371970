/* Castle Css Start Here */
.frame-wrapper-armor {
  /*background-image: url("../img/armory-back.png") !important;*/
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 3rem 2rem;
  margin: 4.5rem 2rem;
  position: relative;
}
.frame-wrapper-armor .centerLogoDv figure img {
  position: absolute;
  left: 0;
  right: 0;
  top: -70px;
  text-align: center;
  margin: 0px auto;
}
.frame-wrapper-armor .border-left img {
  position: absolute;
  left: -22px;
  top: -39px;
}
.frame-wrapper-armor .border-right img {
  position: absolute;
  right: -22px;
  top: -39px;
}
.guild_sec {
  padding: 40px 0 40px;
}
.guild_sec .guild-box .img-box .count {
  position: absolute;
  bottom: 0;
  right: 0;
  background: linear-gradient(
    to right,
    #b88425,
    #c5973f,
    #d2a957,
    #e0bc6f,
    #edcf88,
    #efd28b,
    #f1d58f,
    #f3d892,
    #eacb80,
    #e1bf6f,
    #d9b25d,
    #d0a64c
  ) !important;
  font-family: "Poppins";
  font-weight: 800;
  border-radius: 7px 0 0 0;
  height: 20px;
  line-height: 20px;
  /* width: 18px; */
   width: 100%;
  text-align: center;
}

.guild_sec .guild-box .img-box .count span {
  color: #000;
  font-size: 7px;
  font-family: "Poppins";
  font-weight: 800;
}
.guild_sec .guild-box .img-box .count span.large {
  font-size: 12px;
 
}

.guild_sec .heading-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 63%;
  margin-bottom: 20px;
}
.guild_sec .heading-wrapper .button-group a {
  background-image: linear-gradient(
    to right bottom,
    #000002,
    #101011,
    #1a191a,
    #232323,
    #2c2c2c
  );
  border-radius: 0px;
  color: #e3bf4b !important;
  font-family: "aniron";
  font-size: 11px;
  padding: 7px 55px;
  border: 0px solid;
  border-style: solid;
  border-width: 3px;
  border-image-source: linear-gradient(
    to right,
    #b88425,
    #c5973f,
    #d2a957,
    #e0bc6f,
    #edcf88,
    #efd28b,
    #f1d58f,
    #f3d892,
    #eacb80,
    #e1bf6f,
    #d9b25d,
    #d0a64c
  ) !important;
  border-radius: 4px;
  border-image-slice: 1;
}
.guild_sec .guild-box {
  /*margin-bottom: 50px;*/
}
.guild_sec .guild-box .img-box {
  list-style: none;
  border-style: solid;
  border-width: 2px;
  border-image: radial-gradient(#fff3de, #b88425) 1;
  position: relative;
  /* added by surbzia */
  position: relative;
  text-align: center;
  width: 176px;
}
.guild_sec .guild-box .img-box img {
  /* width: 100%; */
  /* added by surbzia */
  /* width: 200px;
  height: 301px;
  object-fit: cover; */

      width: 142px;
    height: 214px;
    object-fit: cover;
    padding: 1px 1px;
    margin: 23px 16px;
    margin-bottom: 34px;
}
.guild_sec .guild-box .txt {
  margin-top: 20px;
}
.guild_sec .guild-box .txt h4 {
  margin-bottom: 11px;
  color: #e3bf4b;
  font-size: 11px;
   font-family: 'aniron'!important;
}
.guild_sec .guild-box .txt h4 span {
  color: #fff;
  margin-left: 7px;
}
/* Castle Css End Here */
