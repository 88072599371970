.image-uploader .upload-text i {
  display: none;
}
.character-form-wrapper .image-uploader {
  border-style: solid;
  border-width: 3px;
  border-image-source: linear-gradient(
    to right,
    #b88425,
    #c5973f,
    #d2a957,
    #e0bc6f,
    #edcf88,
    #efd28b,
    #f1d58f,
    #f3d892,
    #eacb80,
    #e1bf6f,
    #d9b25d,
    #d0a64c
  ) !important;
  border-image-slice: 1;
  background-image: url("../img/upload.png");
  background-size: cover;
}
.character-form-wrapper input::placeholder{
    color: #fff;
}
.character-form-wrapper select option {
  margin: 40px;
  background: rgba(0, 0, 0, 0.3) !important;
  color: #fff !important;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4) !important;

}
.inputCustomHeight{
  height: 50px!important;
}
.character-form-wrapper select ,
.character-form-wrapper input {
    background-image: linear-gradient(to right bottom, #000002, #101011, #1a191a, #232323, #2c2c2c);
    border-radius: 0px;
    color: #E3BF4B !important; 
    font-family: 'aniron';
    font-size: 10px;
    padding: 10px;
    border: 0px solid;
    border-style: solid;
    border-width: 2px;
    border-image-source: linear-gradient( to right, #b88425, #c5973f, #d2a957, #e0bc6f, #edcf88, #efd28b, #f1d58f, #f3d892, #eacb80, #e1bf6f, #d9b25d, #d0a64c ) !important;
    border-image-slice: 1;
}
.character-form-wrapper button.btn{
    background-image: linear-gradient(to right bottom, #000002, #101011, #1a191a, #232323, #2c2c2c);
    border-radius: 0px;
    color: #E3BF4B !important; 
    font-family: 'aniron';
    font-size: 12px;
    padding: 11px 20px;
    border: 0px solid;
    border-style: solid;
    border-width: 2px;
    border-image-source: linear-gradient( to right, #b88425, #c5973f, #d2a957, #e0bc6f, #edcf88, #efd28b, #f1d58f, #f3d892, #eacb80, #e1bf6f, #d9b25d, #d0a64c ) !important;
    border-image-slice: 1;
}
.character-form-wrapper {
  padding-top: 50px;
  height: 500px;
  overflow: scroll;
}
.upload-text {
  justify-content: end !important;
  padding: 10px;
}
.character-form-wrapper .image-uploader .upload-text span {
  color: #cccccc;
  font-size: 15px;
  font-family: "montserrat";
}
.ammo label {
  display: block;
  color: #e3bf4b;
  font-family: "aniron";
  font-size: 10px;
}
.ammo input.sm {
  display: inline-block;
  width: 17%;
}
.ammo label {
  display: block;
}
/*.frame-wrapper {*/
/*  background-image: url("../img/character-bg.png") !important;*/
/*  background-size: 100% 100%;*/
/*  background-repeat: no-repeat;*/
/*  padding: 3rem 2rem;*/
/*  margin: 4.5rem 2rem;*/
/*  position: relative;*/
/*}*/
.frame-wrapper .centerLogoDv figure img {
  position: absolute;
  left: 0;
  right: 0;
  top: -70px;
  text-align: center;
  margin: 0px auto;
}
.frame-wrapper .border-left img {
  position: absolute;
  left: -22px;
  top: -39px;
}
.frame-wrapper .border-right img {
  position: absolute;
  right: -22px;
  top: -39px;
}
.character-wrapper.active {
  border: 3px solid #cfa550;
}
.character-wrapper {
  position: relative;
  border-radius: 9px;
  margin-bottom: 10px;
}
.character-wrapper figure {
  margin: 0px;
}
.character-list {
  margin-bottom: 42px;
}
.character-wrapper .char-name {
  position: absolute;
  left: 9px;
  top: 33%;
}
.character-wrapper img.char-img {
  filter: brightness(0.5);
  width: 100%;
}
.progress {
  background-color: #2e2e2e;
  height: 0.5rem !important;
  border-radius: 0px !important;
}
.golden-bar {
  background-image: linear-gradient(
    to right,
    #b88425,
    #c5973f,
    #d2a957,
    #e0bc6f,
    #edcf88,
    #efd28b,
    #f1d58f,
    #f3d892,
    #eacb80,
    #e1bf6f,
    #d9b25d,
    #d0a64c
  ) !important;
}
.character-specification .spec-name-and-percent {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
}
.spec-name-and-percent h6 {
  color: #ffe799;
  font-family: "aniron";
  font-weight: bold;
  font-size: 10px;
  margin-bottom: 0px;
}
.spec-name-and-percent h5 {
  color: #ffe799;
  font-family: "aniron";
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 0px;
}
.character-progress {
  margin: 20px 0px;
}
.character-specification {
  margin-top: 45px;
}
a.btn-theme {
  background-image: linear-gradient(
    to right,
    #000002,
    #101011,
    #1a191a,
    #232323,
    #2c2c2c
  );
  background-repeat: no-repeat;
  padding: 15px 70px;
  color: #ffe698 !important;
  font-family: "aniron";
  font-size: 15px;
  border: 3px solid;
  border-image-source: linear-gradient(
    to right,
    #b88425,
    #c5973f,
    #d2a957,
    #e0bc6f,
    #edcf88,
    #efd28b,
    #f1d58f,
    #f3d892,
    #eacb80,
    #e1bf6f,
    #d9b25d,
    #d0a64c
  ) !important;
  border-image-slice: 1;
  border-width: 3px;
}

/* character-sec css start here */
.character {
  padding-top: 60px;
  padding-bottom: 60px;
}
.character p.char-des {
  color: #fff;
  font-weight: 300;
}
.character a.cta {
  text-decoration: underline;
  font-family: "aniron";
  font-size: 11px;
}
.character .character-wrapper {
  margin: 30px 0px;
}
.cta-row {
  padding-top: 80px;
}
/* character-sec css ends here */
